import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {
  clearUserProductCategories,
  fetchProductAllCategories,
} from "../../redux/Actions/productCategoriesAction";
// import ProductContent from './productContent';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setUserProductCategories } from "./../../redux/Actions/productCategoriesAction";
import { fetchAddToCart } from "../../redux/Actions/addCartAction";
import {
  fetchOfferDetailsList,
  fetchPickupDelivery,
} from "../../redux/Actions/checkoutPageActions";
import { fetchSplitPriceDetails } from "../../redux/Actions/splitPriceAction";
import { fetchGetShopByPinCode } from "./../../redux/Actions/checkoutPageActions/index";
import {
  clearCart,
  clearCartCount,
  clearSplitPrice,
  deleteCartCount,
  setCartCount,
  setOrderType,
} from "../../redux/Actions/cartCountAction";
import { setValue } from "../../utility";
import { shirticon } from "../../assets/img";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { toast, ToastContainer } from "react-toastify";
import { fetchOrderDetails } from "../../redux/Actions/orderDetailsAction";
import { fetchShopHomeData } from "../../redux/Actions/shopHomeAction";
import { checkShopListFlow } from "../../utility";
const ProductLists = () => {
  const [productCategories, setProductCategories] = useState([]);
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const state: any = useSelector<any>(
    (state) => state.ProductAllCategories.data
  );
  
  let url = location.pathname;
  let splitURL: any = url.toString().split("/");
  const { cartCount }: any = useSelector<any>((state) => state);
  const settings: any = useSelector<any>((state) => state.settings);
  const shop_details: any = useSelector<any>((state) => state.shopHome);
  const selectedCategories: any = useSelector<any>(
    (state) => state.userSelectedCategories
  );
  const selectedcategoryPriceList: any = useSelector<any>(
    (state) => state.splitPriceDetails
  );

  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0);
  const [addToCarts, setAddToCarts] = useState([]);
  const [userName, setUserName] = useState<any>("");
  const [totalCartPrice, setTotalCartPrice] = useState<any>(0);
  const [totalCartQuantity, setTotalCartQuantity] = useState<any>(0);
  const [amountWithoutVat, setAmountWithoutVat] = useState<any>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [vatAmount, setVatAmount] = useState<any>(0);
  const [cartInformation, setCartInformation] = useState([]);
  const userData: any = useSelector<any>((state) => state);
  const [selectedCategoryResIndex, setSelectedCategoryResIndex] = useState(0);
  const navigate = useNavigate();
  

  const minimum_order_amount: any = useSelector<any>(
    (state) => state.getShopByPinCode?.data?.minimum_order_amount
  );

  const settingsData: any = useSelector<any>((state) => state?.settings);
  
  const navigateToCheckout = () => {
    if (checkShopListFlow()) {
        const shopName: any = localStorage.getItem("currentShopName");
        if (shopName) {
            const finalResult = shopName
                .trim()
                .replace(/\s(.)/g, (match: any) => match.toUpperCase())
                .replace(/\s/g, "")
                .replace(/^(.)/, (match: any) => match.toLowerCase());

            let URL = window.location.href.split("/");
            if (URL.length > 4) {
                navigate(`/shop/${finalResult}/checkOut`);
            } else {
                navigate("/checkout");
            }
        } else {
            navigate("/checkout");
        }
    } else {
        navigate("/checkout");
    }
};

const swiperRef = useRef<any>(null);

const handleNextClick = () => {
  if (swiperRef.current !== null && swiperRef.current.swiper !== undefined) {
      swiperRef.current.swiper.slideNext();
      setSelectedCategoryIndex(prevIndex => prevIndex + 1); 
  }
};

const handlePrevClick = () => {
  if (swiperRef.current !== null && swiperRef.current.swiper !== undefined) {
      swiperRef.current.swiper.slidePrev();
      setSelectedCategoryIndex(prevIndex => prevIndex - 1);
  }
};


  useEffect(() => {
    if (state) {
      setAddToCarts(state.addToCarts);
    }
  }, [state]);

  useEffect(() => {
    if (selectedCategories.length) {
      const cartInformationData = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });

      if (cartInformationData.length) {
        setValue("cartInformationData", cartInformationData);
      }
      setCartInformation(cartInformationData);

      const selectedMainCategory = selectedCategories?.filter((item: any) => {
        return item?.sub_categories?.some(
          (subItem: any) => parseInt(subItem?.quantity) > 0
        );
      });
      let finalTotalPrice = 0;
      let itemsCount = 0;

      selectedMainCategory?.map((item: any) => {
        const subcategoryList = item?.sub_categories?.filter(
          (subItem: any) => parseInt(subItem.quantity) > 0
        );
        itemsCount = itemsCount + subcategoryList?.length;
        subcategoryList?.map((subcategory: any) => {
          finalTotalPrice =
            finalTotalPrice +
            parseInt(subcategory?.quantity) *
              parseFloat(subcategory?.total_price);
        });
      });
      // dispatch(fetchSplitPriceDetails({ finalTotalPrice, itemsCount }));
      if (selectedMainCategory.length == 0) {
        localStorage.setItem("is_removed", "true");
      }
    }
  }, [selectedCategories]);

  const handleContinue = (e: any) => {
    e.preventDefault();
    setValue("orderType", 0);
    const selectedMainCategory = selectedCategories?.filter((item: any) => {
      return item?.sub_categories?.some(
        (subItem: any) => parseInt(subItem?.quantity) > 0
      );
    });

    if (selectedMainCategory.length == 0) {
      toast.warning("Please select atlease one product to continue");
      return false;
    }

    const postalCode = JSON.parse(localStorage.getItem("postalCode")!);
    let shopId = localStorage.getItem("shop_id");
    let URL = window.location.href.split("/");
    if (URL.length > 4) {
      shopId = localStorage.getItem("currentShopHome");
    }
    let finalTotalPrice: any = 0;

    let payload: any = {
      shop_id: shopId,
      postal_code: postalCode,
      total_tax_price: selectedcategoryPriceList?.vat_amount,
      total_price_without_deductions:
        selectedcategoryPriceList?.final_payable_amount,
      total_price_without_vat:
        parseFloat(selectedcategoryPriceList?.total_price_amount) -
        parseFloat(selectedcategoryPriceList?.vat_amount),
      group_id: "1",
      order_type: "0",
      cart_total_count: selectedcategoryPriceList?.itemsCount,
    };

    selectedMainCategory?.map((item: any) => {
      const subcategoryList = item?.sub_categories?.filter(
        (subItem: any) => parseInt(subItem.quantity) > 0
      );

      subcategoryList?.map((subcategory: any) => {
        //TODO: handle thru single selection from the dropdown
        const selectedOption = subcategory?.options[0]; //subcategory?.options?.find((option:any) => option?.isSelected)

        finalTotalPrice =
          finalTotalPrice +
          parseInt(subcategory?.quantity) *
            parseFloat(subcategory?.total_price);
        payload = {
          ...payload,
          [`option_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_id,
          [`process_time_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.process_time,
          [`productcount_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`quantity_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.quantity,
          [`price_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            selectedOption?.option_price,
          [`product_${item?.main_category_id}_${subcategory?.sub_category_id}_${selectedOption?.option_id}`]:
            subcategory?.sub_category_name,
        };
      });
    });

    payload = { ...payload, total_price: finalTotalPrice.toString() };
    localStorage.setItem("order_type", payload.order_type);
    localStorage.setItem("shop_id", payload.shop_id);
    // TODO: call the api and pass the
    // dispatch(fetchPickupDelivery(payload))
    dispatch(fetchAddToCart(payload));
    const userinfo = JSON.parse(localStorage.getItem("userDetails")!);

    if (userinfo) {
      navigateToCheckout();
    }else if(settingsData?.WebsiteSettings?.site_title_en != "Uplands Laundry"){
      localStorage.setItem("isCheckout", "1");
      navigate("/login");
    } else {
      navigate("/guestLogin");
    }

  };
  const handleQuickOrder = () => {
    const userinfo = JSON.parse(localStorage.getItem("userDetails")!);
    if (userinfo) {
      dispatch(setOrderType(1));
      setValue("orderType", 1);
      localStorage.removeItem("cartInformationData");
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      localStorage.removeItem("isloaded");
      // dispatch(clearSplitPrice());
      navigateToCheckout();
    } else {
      localStorage.removeItem("cartInformationData");
      setValue("orderType", 1);
      dispatch(clearCartCount());
      dispatch(clearCart());
      dispatch(clearUserProductCategories());
      localStorage.removeItem("isloaded");
      // dispatch(clearSplitPrice());
      navigate("/guestLogin");
    }
  };

  window.onbeforeunload = (event) => {
    localStorage.removeItem("isloaded");
  };

  useEffect(() => {
    if (state) {
      const { category = [] } = state;
      // setProductCategories(category);
      // 
      // if (category?.length > 0) {
      //   dispatch(setUserProductCategories(category));
      // }
      setProductCategories(category);
      if (category?.length > 0) {
        if (!localStorage.getItem("isloaded")) {
          if (localStorage.getItem("is_removed")) {
            let cat = category?.filter((item: any) => {
              return item?.sub_categories?.some(
                (subItem: any) => parseInt(subItem?.quantity) > 0
              );
            });

            if (cat.length == 0) {
              cat = category;
            }

            let subcat = cat[0]?.sub_categories?.find(
              (subItem: any) => parseInt(subItem?.quantity) > 0
            );

            if (subcat == undefined || subcat.length == 0) {
              subcat = category[0]?.sub_categories[0];
            }

            const selectedCategoryItem: any = category?.find(
              (item: any) =>
                item.main_category_id === cat[0].main_category_id.toString()
            );

            const getSubCategory = selectedCategoryItem?.sub_categories?.find(
              (item: any) => item.sub_category_id === subcat.sub_category_id
            );

            const updatedSubCategory = {
              ...getSubCategory,
              quantity: 0,
            };

            const updatedSubCategoriesList =
              selectedCategoryItem?.sub_categories?.map((item: any) => {
                if (item.sub_category_id === subcat.sub_category_id) {
                  return updatedSubCategory;
                }
                return item;
              });

            const updatedCategory = {
              ...selectedCategoryItem,
              sub_categories: updatedSubCategoriesList,
            };
            const updatedCategoriesList = category?.map((item: any) => {
              if (
                item.main_category_id === (cat[0]?.main_category_id).toString()
              ) {
                return updatedCategory;
              }
              return item;
            });

            dispatch(setUserProductCategories(updatedCategoriesList));
          } else {
            dispatch(setUserProductCategories(category));
          }
          localStorage.setItem("isloaded", "true");
        }
      }
    }
  }, [state]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        toast.dismiss();
        const queryParams = new URLSearchParams(location.search);
        const hasNoParams = queryParams.keys().next().done;

        let postalCode;

        if (hasNoParams) {
          postalCode = localStorage.getItem("postalCode");
        } else {
          localStorage.clear();
          const queryData = queryParams.get("data");
          const decryptHexa = (hex: any) => {
            let str = "";
            for (let i = 0; i < hex.length; i += 2) {
              const hexValue = hex.substr(i, 2);
              const decimalValue = parseInt(hexValue, 16);
              str += String.fromCharCode(decimalValue);
            }
            return str;
          };

          const hexToString = decryptHexa(queryData);
          const dataConvertedArray = hexToString.split("&");
          const queryValues: any = {};

          dataConvertedArray.forEach((item) => {
            const [key, value] = item.split("=");
            queryValues[key] = value.replace(/"/g, "").trim();
          });
          postalCode = JSON.stringify(queryValues.postalCode);
          const shopId = queryValues.shop_id || "1";

          if (queryValues.label && queryValues.value) {
            const address = JSON.stringify({
              label: queryValues.label,
              value: queryValues.value,
            });
            localStorage.setItem("selectedAddressBeforeLogin", address);
          }

          localStorage.setItem("postalCode", postalCode);
          localStorage.setItem("shop_id", shopId);
        }

        if (!postalCode || postalCode === "undefined" || postalCode === null) {
          toast.warning("Enter postal code in Book Now Section");
          navigate("/");
        } else {
          await dispatch(fetchProductAllCategories());
          const res = await dispatch(fetchGetShopByPinCode(postalCode));

          if (res?.data?.Response?.response_code === "-1") {
            // Handle error or navigate elsewhere
            navigate("/");
          }
        }
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const repeatOrderId = localStorage.getItem("order_id");
    if (
      repeatOrderId != "" ||
      repeatOrderId != undefined ||
      repeatOrderId != null
    ) {
      // repeatOrderData(repeatOrderId).then(()=>{
      //   localStorage.removeItem("order_id")
      // });
    }
  }, [productCategories]);

  // useEffect(()=>{
  //    dispatch(setCartCount(selectedCategories))
  // },[selectedCategories]);

  // useEffect(()=>{
  //     const cartInformationData = selectedCategories?.filter((item: any) => {
  //       return item?.sub_categories?.some(
  //         (subItem: any) => parseInt(subItem?.quantity) > 0
  //       );
  //     });
  //     setCartCount(cartInformationData);
  //   },[selectedCategories])

  const onCategoryClickHandler = (selectedCategoryId: any) => {
    //

    setSelectedCategoryIndex(
      selectedCategories.findIndex(
        (obj: any) => obj.main_category_id === selectedCategoryId
      )
    );

    // setSelectedCategory(productCategories?.find(
    //   (item: any) =>
    //     item.main_category_id === (selectedCategoryId).toString()
    // ));

    /* const selectedCategoryItem: any = productCategories?.find((item: any) => item.main_category_id === selectedCategoryId);
        if (selectedCategoryItem) {
            
            dispatch(setUserProductCategories(selectedCategoryItem));
        } */
  };

  const onAddToCartItemHandler = (
    selectedSubCategoryId: any,
    selectedMainCategoryId: any,
    type: any,
    orderData?: any
  ) => {
    const selectedCategoryItem: any =
      type === "reorder"
        ? productCategories.find(
            (item: any) =>
              item.main_category_id === selectedMainCategoryId.toString()
          )
        : selectedCategories?.find(
            (item: any) =>
              item.main_category_id === selectedMainCategoryId.toString()
          );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );
    const { quantity = 0 } = getSubCategory;
    let updatedQuantity = parseInt(quantity);
    if (type === "reorder") {
      const repeatOrderMainCat: any = orderData?.find(
        (item: any) =>
          item.main_category_id === selectedMainCategoryId.toString()
      );
      const repeatOrderSubCat = repeatOrderMainCat?.items?.find(
        (item: any) => item.sub_category_id === selectedSubCategoryId
      );
      updatedQuantity = repeatOrderSubCat.item_quantity;
      getSubCategory.quantity = repeatOrderSubCat.item_quantity;
      dispatch(setCartCount(repeatOrderSubCat.item_quantity));
    } else if (type === "minus") {
      updatedQuantity = updatedQuantity - 1;
      dispatch(deleteCartCount(1));
    } else {
      localStorage.removeItem("is_removed");
      updatedQuantity = updatedQuantity + 1;
      dispatch(setCartCount(cartCount + 1));
    }

    const updatedSubCategory = {
      ...getSubCategory,
      quantity: updatedQuantity.toString(),
    };

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });

    dispatch(setUserProductCategories(updatedCategoriesList));
  };

  function handleOptionChange(
    e: any,
    selectedMainCategoryId: any,
    selectedSubCategoryId: any
  ) {
    let selectedOption = e.target.value;
    let selectedOptionPrice = "0";

    const selectedCategoryItem: any = selectedCategories?.find(
      (item: any) => item.main_category_id === selectedMainCategoryId.toString()
    );

    const getSubCategory = selectedCategoryItem?.sub_categories?.find(
      (item: any) => item.sub_category_id === selectedSubCategoryId
    );

    const getOption = getSubCategory?.options?.map((option: any) => {
      if (option.option_id == selectedOption) {
        selectedOptionPrice = option.option_price;
        return { ...option, is_default: 1 };
      } else {
        return { ...option, is_default: 0 };
      }
    });

    //

    let option = getOption;
    // let updatedQuantity = parseInt(quantity);

    const updatedSubCategory = {
      ...getSubCategory,
      options: option,
      total_price: selectedOptionPrice,
    };

    //

    const updatedSubCategoriesList = selectedCategoryItem?.sub_categories?.map(
      (item: any) => {
        if (item.sub_category_id === selectedSubCategoryId) {
          return updatedSubCategory;
        }
        return item;
      }
    );

    const updatedCategory = {
      ...selectedCategoryItem,
      sub_categories: updatedSubCategoriesList,
    };

    const updatedCategoriesList = selectedCategories?.map((item: any) => {
      if (item.main_category_id === selectedMainCategoryId.toString()) {
        return updatedCategory;
      }
      return item;
    });

    dispatch(setUserProductCategories(updatedCategoriesList));
  }

  const repeatOrderData = async (id: any) => {
    const response = await dispatch(fetchOrderDetails(JSON.parse(id)));
    if (response.data.Response.response_code == "1") {
      const order: any[] = response?.data?.data?.orders_details?.order_items;
      if (order.length > 0) {
        for (let main_index = 0; main_index < order.length; main_index++) {
          const mainCat = order[main_index].items;
          const main_category_id = order[main_index].main_category_id;
          for (let sub_index = 0; sub_index < mainCat.length; sub_index++) {
            const sub_category_id = mainCat[sub_index].sub_category_id;
            setTimeout(() => {
              onAddToCartItemHandler(
                sub_category_id,
                main_category_id,
                "reorder",
                order
              );
            }, sub_index * 10);
          }
        }
      } else {
        
      }
    }
  };

  // const cartInformation = selectedCategories?.filter((item: any) => {
  //   return item?.sub_categories?.some(
  //     (subItem: any) => parseInt(subItem?.quantity) > 0
  //   );
  // });

  //Construct the api request based on cartInformation
  useEffect(() => {
    if (splitURL.length > 3) {
      dispatch(fetchShopHomeData());
    }
  }, []);

  useEffect(() => {
  let totalQuantity = 0;
  let totalPrice = 0;
  cartInformation?.forEach((item: any) => {
    item?.sub_categories
        ?.filter(
            (subItem: any) =>
                parseInt(subItem.quantity) > 0
        )
        ?.forEach((subCategory: any) => {
            totalQuantity += parseInt(subCategory.quantity);
            totalPrice += parseFloat(subCategory.total_price) * parseFloat(subCategory.quantity);
        });
});

if(selectedcategoryPriceList.final_payable_amount < totalPrice){
  setTotalCartPrice(totalPrice.toFixed(2));
  getTotalCartValue(totalPrice.toFixed(2));
}else{
  setTotalCartPrice(selectedcategoryPriceList.final_payable_amount);
  getTotalCartValue(selectedcategoryPriceList.final_payable_amount);
}

setTotalCartQuantity(totalQuantity);


}, [cartInformation,selectedcategoryPriceList]);

const getTotalCartValue = (totalPrice:any) => {
  setTotalCartPrice(totalPrice);
const vatPercentage = parseFloat(selectedcategoryPriceList.vat_percentage);
    const amountWithoutVat = totalPrice / (1 + vatPercentage / 100);
    const vatAmount = totalPrice - amountWithoutVat;
setAmountWithoutVat(amountWithoutVat);
setVatAmount(vatAmount);
}

  return (
    <div>
      <div className="breadcrumpset d-none">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>PRODUCT LIST</h2>
                <ul>
                  <li key={1}>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li key={2}>
                    <span> Product List</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {checkShopListFlow() && 
      <div className="section-shop-info">
        <div className="container">
          <div className="row section-shop-hr">
            <div className="col-md-4">
              <div className="sec-shop-img">
                <img src={shop_details?.home_page_settings?.shop_home_page_image?.banner[0]?.file_name} alt="" className="img-fluid" />
              </div>
            </div>
            <div className="col-md-8">
              <div className="sec-shop-details">
                <h2>{shop_details?.profile?.shop_name}</h2>
                <p className="sec-shop-adrs">
                  <i className="fas fa-map-marker-alt me-2"></i>
                  {shop_details?.profile?.address.text_address}
                </p>
                <ul>
                  <li>
                    <i className="fas fa-star me-2"></i>
                    <b>{settings?.WebmasterSettings?.currency}
                    {shop_details?.profile?.minimum_order_amount}</b> minimum
                  </li>
                  <li>
                    <i className="fas fa-star me-2"></i>Well Trained Staffs
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <div className="section-orderlist first-background">
        <div className="">
          <div className="container">
            <div className="selection-page-wrapper">
              <div className="list-breadcums">
                <div className="row">
                  <div className="col-md-6 col-sm-4 d-none d-md-block">
                    <ul>
                      <li key={3}>
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            navigate("/");
                          }}
                        >
                          Home
                        </a>
                      </li>
                      /<li key={4}>Product List</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* <div className="quick-order-btn mb-15 mobile-order-btn">
                                <a className="btn btn-lg" href="#" >Skip to <strong>Quick Order</strong> <i className="fa fa-info-circle fa-1" title="we'll collect your bag and bill you after"></i></a>
                            </div> */}

              <div className="row">
                <div className="col-sm-12 col-lg-8">
                  <div className="prodlistswipe">
                    <div className="prodlistswipe-buttons d-md-none">
                      <button onClick={handleNextClick} disabled={selectedCategoryIndex === productCategories.length - 1}><i className="fa-solid fa-angle-left"></i></button>
                      <button onClick={handlePrevClick} disabled={selectedCategoryIndex === 0}><i className="fa-solid fa-angle-right"></i></button>
                    </div>
                  <Swiper
                    ref={swiperRef}
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                    {productCategories &&
                      productCategories.map((item: any,index:any) => {
                        return (
                          <SwiperSlide>
                            <div
                              onClick={() => {
                                onCategoryClickHandler(item.main_category_id);
                                setSelectedCategoryResIndex(index);
                            }}
                              className={
                                selectedCategories[selectedCategoryIndex]
                                  ?.main_category_id == item?.main_category_id
                                  ? "category-container active-swiper"
                                  : "category-container"
                              }
                            >
                              <div role="tab" id="heading-1">
                                <a
                                  role="button"
                                  className=""
                                  data-bs-toggle="collapse"
                                  data-parent="#accordion"
                                  href="#collapse-1"
                                  aria-expanded="true"
                                  aria-controls="collapse-1"
                                >
                                  <div className="order_list orderlist-first">
                                    <div className="user-img">
                                      <img
                                        src={item.web_banner_img}
                                        className="img-fluid"
                                        alt=""
                                      />
                                    </div>
                                    <div className="list-explore">
                                      <p>{item.main_category_name}</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                  </Swiper>                  
                  </div>
                  <div className="row my-4">
                    {selectedCategories?.length > 0 &&
                      selectedCategories[
                        selectedCategoryIndex
                      ]?.sub_categories.map((item: any) => {
                        return (
                          <div className="col-md-12 col-custom">
                            <div
                              className="panel-body collapse-body list-cloth"
                              id="list-cart"
                            >
                              <div className="list_li">
                                <div className="product">
                                  <div className="pro-det">
                                    {!settingsData?.WebsiteSettings?.site_title_en.includes(
                                      "Whiterose"
                                    ) && (
                                      <img
                                        className="d-none"
                                        src={item.web_sub_category_img}
                                        title={item.sub_category_name}
                                      />
                                    )}
                                    <div>
                                      <h5
                                        className="subcategory-name"
                                        data-sub="1"
                                      >
                                        {item.sub_category_name}
                                      </h5>
                                      <span className="fonsize-10">
                                        {item.description}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="btn-group select-btn">
                                    <select
                                      className={`catselect ${
                                        "show_product_options" in
                                          settingsData?.WebmasterSettings &&
                                        settingsData?.WebmasterSettings
                                          ?.show_product_options == 0
                                          ? "d-none"
                                          : ""
                                      }`}
                                      onChange={(e) => {
                                        handleOptionChange(
                                          e,
                                          selectedCategories[
                                            selectedCategoryIndex
                                          ].main_category_id,
                                          item.sub_category_id
                                        );
                                      }}
                                    >
                                      {item.options.map((subItem: any) => {
                                        return (
                                          <option
                                            value={subItem.option_id}
                                            selected={
                                              subItem.is_default == "1"
                                                ? true
                                                : false
                                            }
                                          >
                                            {subItem?.option_name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="price-amount">
                                    <div className="amount-list">
                                      <div className="amount">
                                        <span>
                                          {
                                            settings?.WebmasterSettings
                                              ?.currency
                                          }
                                        </span>
                                        {parseInt(item?.quantity) === 0
                                          ? parseFloat(item?.total_price)
                                          : parseFloat(item?.total_price)}
                                      </div>
                                    </div>
                                    <div className="amountlist" id="amountlist">
                                      <div className="listcloth-rht">
                                        <span>
                                          <button
                                            type="button"
                                            className="quantity-left-minus btn btn-number"
                                            data-type="minus"
                                            data-field=""
                                            disabled={item.quantity === "0"}
                                            onClick={() =>
                                              onAddToCartItemHandler(
                                                item.sub_category_id,
                                                selectedCategories[
                                                  selectedCategoryIndex
                                                ].main_category_id,
                                                "minus"
                                              )
                                            }
                                          >
                                            <span className="glyphicon glyphicon-minus"></span>
                                          </button>
                                        </span>
                                        <span className="prodcountinp">
                                          {item?.quantity}
                                        </span>
                                        <span>
                                          <button
                                            type="button"
                                            className="quantity-right-plus btn btn-number"
                                            data-type="plus"
                                            data-field=""
                                            onClick={() =>
                                              onAddToCartItemHandler(
                                                item.sub_category_id,
                                                selectedCategories[
                                                  selectedCategoryIndex
                                                ].main_category_id,
                                                "add"
                                              )
                                            }
                                          >
                                            <span className="glyphicon glyphicon-plus"></span>
                                          </button>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-sm-12 col-lg-4">
                  <div className="theiaStickySidebar">
                    <div className="pl-style">
                      <div className="skiptoquickorder text-center">
                        <div className="quick-order-btn mb-15">
                          <button
                            className="btn btn-lg"
                            onClick={handleQuickOrder}
                          >
                            Skip to <strong>Quick Order</strong>
                          </button>
                        </div>
                        <p className="mb-0 text-muted">
                          <i className="fa fa-info-circle"></i> We'll collect
                          your bag and bill you after
                        </p>
                      </div>
                      <div className="price-details Ord-summary cart-summary">
                        <div className="price-header text-center">
                          <h2>Order summary</h2>
                        </div>
                        <div className="price-body">
                          {cartInformation?.map((item: any) => {
                            return (
                              <>
                                <div className="cs-option-price">
                                  <span className="cs-summary-title">
                                    <h4>{item?.main_category_name}</h4>
                                  </span>
                                  {item?.sub_categories
                                    ?.filter(
                                      (subItem: any) =>
                                        parseInt(subItem.quantity) > 0
                                    )
                                    ?.map((subCategory: any) => {
                                      return (
                                        <div className="cs-option-body">
                                          <div className="cs-option-left">
                                            <span>
                                              {subCategory?.sub_category_name}{" "}
                                            </span>
                                            <span className="cs-option-pqty">
                                              ({subCategory?.quantity})
                                            </span>
                                          </div>
                                          <div className="cs-option-right">
                                            {
                                              settings?.WebmasterSettings
                                                ?.currency
                                            }
                                            &nbsp;
                                            {(
                                              parseFloat(
                                                subCategory?.quantity
                                              ) *
                                              parseFloat(
                                                subCategory?.total_price
                                              )
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </>
                            );
                          })}
                          <div className="cs-option-amt">
                            <div className="cs-option-amt-list">
                              <div className="cs-option-amt-left">
                                {/* Price ({selectedcategoryPriceList?.itemsCount}{" "}
                                items) */}
                                Price ({totalCartQuantity}{" "}
                                items)
                              </div>
                              <div className="cs-option-amt-right">
                                {settings?.WebmasterSettings?.currency}{" "}
                                {/* {selectedcategoryPriceList?.total_price_amount} */}
                                {totalCartPrice}
                              </div>
                            </div>

                            {selectedcategoryPriceList?.vat_percentage > 0 ? (
                              <>
                                <div className="cs-option-amt-list">
                                  <div className="cs-option-amt-left">
                                    Amount without VAT
                                  </div>
                                  <div className="cs-option-amt-right">
                                    {settings?.WebmasterSettings?.currency}{" "}
                                    {/* {(
                                      parseFloat(
                                        selectedcategoryPriceList?.total_price_amount
                                      ) -
                                      parseFloat(
                                        selectedcategoryPriceList?.vat_amount
                                      )
                                    ).toFixed(2)} */}
                                    {amountWithoutVat.toFixed(2)}
                                  </div>
                                </div>
                                <div className="cs-option-amt-list">
                                  <div className="cs-option-amt-left">
                                    VAT (
                                    {selectedcategoryPriceList?.vat_percentage}
                                    %)
                                  </div>
                                  <div className="cs-option-amt-right">
                                    {settings?.WebmasterSettings?.currency}{" "}
                                    {/* {selectedcategoryPriceList?.vat_amount} */}
                                    {vatAmount.toFixed(2)}
                                  </div>
                                </div>
                              </>
                            ) : null}
                            <div className="orders-total">
                              <span className="lft-ordertitle">
                                Delivery Charges
                              </span>
                              {selectedcategoryPriceList.pickup_and_delivery_charge >
                              0 ? (
                                <span className="rht-orderamt float-end">
                                  {settings?.WebmasterSettings?.currency}{" "}
                                  {
                                    selectedcategoryPriceList.pickup_and_delivery_charge
                                  }
                                </span>
                              ) : (
                                <span className="rht-orderamt float-end">
                                  Free
                                </span>
                              )}
                              <small className="text-danger">Note : Delivery charges may vary on checkout</small>
                            </div>

                            <div className="cs-option-amt-list cs-option-amt-listbg">
                              <div className="cs-option-amt-left">
                                Amount Payable
                              </div>
                              <div className="cs-option-amt-right">
                                {settings?.WebmasterSettings?.currency}{" "}
                                {/* {
                                  selectedcategoryPriceList?.final_payable_amount
                                } */}
                                {totalCartPrice}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="price-footer">
                          <button
                            type="submit"
                            id="submit-btn"
                            className="continue-btn hover-btn center-block"
                            data-quantity="2"
                            data-price="70.00"
                            // disabled = {selectedcategoryPriceList?.itemsCount === 0}
                            onClick={handleContinue}
                          >
                            Continue{" "}
                            <span className="option-total total-amt">
                              {settings?.WebmasterSettings?.currency}{" "}
                              {/* {selectedcategoryPriceList?.final_payable_amount} */}
                              {totalCartPrice}
                            </span>
                          </button>
                        </div>
                        <div className="text-center price-footer-notes">
                          <small>
                            <b>Note:</b> Minimum order amount is{" "}
                            {settings?.WebmasterSettings?.currency}
                            {minimum_order_amount || ""}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="resize-sensor">
                      <div className="resize-sensor-expand">
                        <div></div>
                      </div>
                      <div className="resize-sensor-shrink">
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ProductLists;
