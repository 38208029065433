import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import PricingList from "../../pricingList/index";
import { fetchPostalCodes } from "../../../redux/Actions";
import { setValue } from "../../../utility";
import { appstore, googleplay, appdownload } from "../../../assets/img";

import SampleVideo from "./../../../images/sample.mp4";
import { DebounceInput } from "react-debounce-input";

import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { UKPostalCode } from "../../core/regex";
import {
  fetchAddressByPincode,
  fetchGetShopByPinCode,
  fetchSaveAddress,
} from "../../../redux/Actions/checkoutPageActions";

const searchPinCodeFormSchema = yup.object().shape({
  pinCode: yup
    .string()
    .required("Pin code is required")
    .matches(UKPostalCode, "Enter a valid Pin code")
    .trim()
    .max(8, "Enter a valid Pin code"),
});

const addressFormSchema = yup.object().shape({
  address: yup
    .object()
    .shape({
      label: yup.string().required("Address is required to proceed"),
      value: yup.string().required("Address is required to proceed"),
    })
    .test("address.value", "Address is required to proceed", (value: any) => {
      if (value.value == "") {
        return false;
      } else return true;
    })
    .required("Address is required to proceed"),
});

function ThemeTwoOld() {
  const [postalCodeList, setPostalCodeList] = useState([]);
  const [postalCodeValue, setPostalCodeValue] = useState("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector((state) => state);
  const navigate = useNavigate();
  const [checkWithNewPin, setCheckWithNewPin] = useState(true);
  const [selectedAddress, setSelectedAddress] = useState<any>(null);
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const shopHomePageItems = shopHomeData;

  const appStoreLink = shopHomePageItems?.webmaster_settings?.app_store_link;
  const playStoreLink = shopHomePageItems?.webmaster_settings?.play_store_link;
  const areaOptions: any = useSelector<any>(
    (state) => state?.addressByPincode?.data?.address_list
  );
  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      // setValue("postalCode",state.postalCodeList[0]?.postcode)
    }
  }, [state]);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const [sticky, setSticky] = useState("");

  useEffect(() => {
    if (state) {
      setPostalCodeList(state.postalCodeList);
      setValue("postalCode", state.postalCodeList[0]?.postcode.toUpperCase());
    }
  }, [state]);

  // on render, set listener
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 100 ? "navbar-reduce" : "";
    setSticky(stickyClass);
  };

  const fixednav = `navbar navbar-default navbar-trans navbar-expand-lg ${sticky}`;

  const {
    handleSubmit: handlePinCodeSubmit,
    control: pinCodeFormControl,
    setError: setPinCodeFormError,
    setValue: setPinCodeFormValue,
    formState: { errors: pinCodeFormError },
  } = useForm({
    resolver: yupResolver(searchPinCodeFormSchema),
    mode: "onChange",
    defaultValues: {
      pinCode: "",
    },
  });

  const {
    handleSubmit: handleAddressSubmit,
    control: addressFormControl,
    setError: addressFormSetError,
    setValue: setAddressFormValue,
    getValues: getValuesAddressForm,
    reset: resetAddressForm,
    clearErrors,
    formState: { errors: addressFormError },
  } = useForm({
    resolver: yupResolver(addressFormSchema),
    mode: "onChange",
    defaultValues: {
      address: { value: "", label: "Select your address..." },
    },
  });
  const handleAddressChange = async (selected: any) => {
    setSelectedAddress(selected);
    setAddressFormValue("address", selected);
    if (getValuesAddressForm("address")?.value != "") {
      clearErrors();
    }
  };

  const submitAddressChange = async () => {
    if (getValuesAddressForm("address")?.value == "") {
      addressFormSetError("address", {
        type: "server",
        message: "Address is required to proceed",
      });
    } else {
      clearErrors();
      localStorage.setItem(
        "selectedAddressBeforeLogin",
        JSON.stringify(selectedAddress)
      );
      if (localStorage.getItem("token")) {
        // handle api
        let postalCode: any = localStorage.getItem("postalCode");
        const contact_name = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.name;
        const mobile_number = JSON.parse(localStorage.getItem("userDetails")!)
          .data?.user_info.mobile;
        const address = selectedAddress?.value?.split(",");
        const payloadData = {
          address_type: "pickup",
          address_details: `{"contact_name":"${contact_name}","mobile_number":"${mobile_number}","line_1":"${address[0].trim()}","line_2":"${address[1].trim()}","line_3":"${address[2].trim()}","line_4":"${address[3].trim()}","locality":"${address[4].trim()}","city":"${address[5].trim()}","county":"${address[6].trim()}","label":"Home","pincode":"${JSON.parse(
            postalCode
          )}"}`,
        };
        const uniqueAddress = await dispatch(fetchSaveAddress(payloadData));
        if (
          uniqueAddress.status ||
          uniqueAddress.message == "Address already exists"
        ) {
          navigate("/productLists");
        } else {
          addressFormSetError("address", {
            type: "server",
            message: "Unable to set address try again later",
          });
        }
      } else {
        navigate("/productLists");
      }
    }
  };

  const submitPostalForm = async (data: any) => {
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    const shop = await dispatch(
      fetchGetShopByPinCode(JSON.stringify(data.pinCode))
    );
    localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
    if (shop) {
      const result = await dispatch(fetchAddressByPincode());
      if (result.status == 200 && result.data.data?.address_list.length > 0) {
        setCheckWithNewPin(false);
        localStorage.setItem("postalCode", JSON.stringify(data.pinCode));
      } else {
        setPinCodeFormError("pinCode", {
          type: "server",
          message: "Area not covered",
        });
      }
    } else {
      setPinCodeFormError("pinCode", {
        type: "server",
        message: "Area not covered",
      });
    }
  };

  return (
    <div>
      <div className="shophome theme2">
        <header>
          {/* <div className="navbar-top">
            <div className="container-fluid">
              <div className="row header-nav">
                <div className="col-6 col-sm-6">
                  <p>
                    <span>Ph no: </span>
                    <span>
                      <a
                        href={`tel:${
                          Object.keys(shopHomePageItems).length > 0 &&
                          shopHomePageItems?.profile?.mobile_number
                        }`}
                      >
                        {Object.keys(shopHomePageItems).length > 0 &&
                          shopHomePageItems?.profile?.mobile_number}
                      </a>
                    </span>
                  </p>
                </div>
                <div className="col-6 col-sm-6 header-right">
                  <p className="float-end">
                    <span>Email : </span>
                    <span>
                      <a
                        href={`mailto:${
                          Object.keys(shopHomePageItems).length > 0 &&
                          shopHomePageItems?.profile?.email
                        }`}
                      >
                        {Object.keys(shopHomePageItems).length > 0 &&
                          shopHomePageItems?.profile?.email}
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <nav className={fixednav} id="navbar-example2">
            <div className="container-fluid top-nav-bar">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarDefault"
                aria-controls="navbarDefault"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              <a className="navbar-brand text-brand" href="/">
                <img
                  src={
                    shopHomePageItems?.home_page_settings?.shop_home_page_image
                      .logo[0].file_name
                  }
                  className="img-fluid shop-logo"
                  alt="Image"
                />
              </a>
              <div
                id="navbarDefault"
                className="themenavbar navbar-collapse collapse "
              >
                <ul className="navbar-nav float-right">
                  <li className="nav-item active">
                    <a className="nav-link" href="#section-one">
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#section-three">
                      Services
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#section-four">
                      Price
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#footer">
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </header>
        <div
          data-bs-spy="scroll"
          data-bs-target="#navbar-example2"
          data-bs-offset="0"
          className="scrollspy-example"
        >
          <section
            className="section-banner home-slider-banner form-search"
            id="section-one"
          >
            <div id="sppb-addon-1482347248" className="clearfix">
              <div className="sppb-addon flex">
                <div className="slick-carousel-468 clearfix slick-initialized slick-slider slick-dotted">
                  <div
                    className="slick-img slick-slide slick-current slick-active"
                    data-slick-index="0"
                    aria-hidden="false"
                  >
                    <img
                      src={
                        shopHomePageItems?.home_page_settings
                          ?.shop_home_page_image?.banner[0]?.file_name
                      }
                      alt="Image"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="custom-search-laundry">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-10 mx-auto">
                    <div className="single-search-blk p-md-4 p-xl-5">
                      <h1>
                        A{" "}
                        <span className="bluetext">
                          Professional Dry Cleaning
                        </span>{" "}
                        and <span className="yellowtext">Laundry Service</span>{" "}
                        in the UK
                      </h1>
                      <p>
                        {shopHomePageItems?.home_page_settings?.banner_text}
                      </p>
                      <div className="postcodeform-container">
                        <form onSubmit={handlePinCodeSubmit(submitPostalForm)}>
                          <div
                            className={`${
                              checkWithNewPin == false ? "d-none" : ""
                            }`}
                          >
                            <small className="text-danger postvalidation">
                              {pinCodeFormError.pinCode?.message}
                            </small>
                            <div className="postcodeform">
                              <Controller
                                name="pinCode"
                                control={pinCodeFormControl}
                                render={({ field }) => (
                                  <input
                                    type="text"
                                    id="postcode-input"
                                    {...field}
                                    placeholder="Enter Postcode"
                                    className={`ui-autocomplete-input ${
                                      pinCodeFormError.pinCode ? "invalid" : ""
                                    }`}
                                  />
                                )}
                              />
                              <button
                                className="btn hover-btn"
                                id="book_now_btn"
                                type="submit"
                              >
                                Book Now
                              </button>
                            </div>
                          </div>
                        </form>
                        <form>
                          <div
                            className={`adrsselectform ${
                              checkWithNewPin == true ? "d-none" : ""
                            }`}
                          >
                            <small className="text-danger postvalidation">
                              {addressFormError.address?.message}
                            </small>
                            <div className="adrsselectdd">
                              <Controller
                                name="address"
                                control={addressFormControl}
                                render={({ field }) => (
                                  <Select
                                    placeholder="Select your address"
                                    {...field}
                                    value={selectedAddress}
                                    className={`${
                                      addressFormError.address ? "invalid" : ""
                                    }`}
                                    onChange={handleAddressChange}
                                    onFocus={() => {
                                      if (
                                        getValuesAddressForm("address").value ==
                                        ""
                                      ) {
                                        addressFormSetError("address", {
                                          type: "server",
                                          message:
                                            "Address is required to proceed",
                                        });
                                      } else {
                                        clearErrors();
                                      }
                                    }}
                                    options={areaOptions}
                                  />
                                )}
                              />

                              <input
                                type="button"
                                value="Continue"
                                className="btn hover-btn"
                                onClick={submitAddressChange}
                              />
                            </div>
                            <div className="adrsselect-or">OR</div>
                            <div className="adrsselect-link">
                              <a
                                className="cursor-pointer"
                                onClick={() => {
                                  setCheckWithNewPin(true);
                                }}
                              >
                                Check with another Postcode
                              </a>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="about-section" id="section-three">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-12 col-lg-5">
                  <div className="section-title aos-init" data-aos="fade-down">
                    <small>Welcome to {shopHomePageItems?.profile?.name}</small>
                    <h2>
                      About &amp; <span>Service</span>
                    </h2>
                  </div>
                  <div className="about-service aos-init" data-aos="fade-down">
                    <p className="show-more">
                      {shopHomePageItems?.home_page_settings?.about_description}
                    </p>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-lg-7">
                  <div className="about-slider aos-init" data-aos="fade-down">
                    <img
                      src={
                        shopHomePageItems?.home_page_settings
                          ?.shop_home_page_image?.gallery[0]?.file_name
                      }
                      className="img-fluid"
                      alt="Image"
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div id="section-four">
            <PricingList />
          </div>

          <section className="app-section" id="section-five">
            <div className="container-fluid">
              <div className="row justify-content-center">
                <div className="col-sm-12">
                  <div className="app-section-title text-center wow fadeInDown">
                    <h2>
                      <span>Download Our App</span>
                    </h2>
                    <p>
                      With our award-winning app, your laundry and dry cleaning
                      needs are just a tap away
                    </p>
                    <ul className="p-0 mt-4 app-link">
                      <li className="d-inline-block">
                        <a target="_blank" href={appStoreLink}>
                          <img
                            src={appstore}
                            className="img-fluid"
                            alt="Image"
                          />
                        </a>
                      </li>
                      <li className="d-inline-block">
                        <a target="_blank" href={playStoreLink}>
                          <img
                            src={googleplay}
                            className="img-fluid"
                            alt="Image"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <div className="app-download wow fadeInDown">
                  <div className="app-download-img">
                    <img
                      src="https://www.bestatlaundry.com/general/theme/blue_theme/img/app/appdownload.png"
                      className="img-fluid"
                      alt="Image"
                    />
                    <img
                      src="https://www.bestatlaundry.com/general/theme/blue_theme/img/app/appdownload1.png"
                      className="img-fluid app-img"
                      alt="Image"
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </section>
          <footer className="footer-section" id="footer">
            <div className="container-fluid">
              <div className="row">
                {/* <div className="showhide-footer mx-auto text-center">
                  <a className="fa-down" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-controls="collapseExample"><i className="fa fa-angle-down"></i></a>
               </div> */}
                <div
                  className="col-12 p-5 footer-first-section collapse show"
                  id="collapseExample"
                >
                  <div className="justify-content-center text-center wow fadeInDown">
                    <p className="m-3">
                      <span>Contact : </span>
                      <span>
                        <a
                          href={`tel:${
                            Object.keys(shopHomePageItems).length > 0 &&
                            shopHomePageItems?.profile?.mobile_number
                          }`}
                        >
                          {Object.keys(shopHomePageItems).length > 0 &&
                            shopHomePageItems?.profile?.mobile_number}
                        </a>
                      </span>
                    </p>
                    {/* <p className="m-3">Email : {shopHomePageItems?.profile?.email}</p> */}
                    <p className="m-3">
                      <span>Email : </span>
                      <span>
                        <a
                          href={`mailto:${
                            Object.keys(shopHomePageItems).length > 0 &&
                            shopHomePageItems?.profile?.email
                          }`}
                        >
                          {Object.keys(shopHomePageItems).length > 0 &&
                            shopHomePageItems?.profile?.email}
                        </a>
                      </span>
                    </p>
                    <p className="m-3">
                      {shopHomePageItems?.profile?.address_line_1},{" "}
                      {shopHomePageItems?.profile?.address_line_2},{" "}
                      {shopHomePageItems?.profile?.address_line_3},{" "}
                      {shopHomePageItems?.profile?.city},{" "}
                      {shopHomePageItems?.profile?.country}
                    </p>
                  </div>
                  <hr className="hr-footer mt-3" />
                  <div className="d-flex">
                    <ul className="social-network social-circle list-inline mx-auto justify-content-center"></ul>
                  </div>
                  <h5 className="text-center pt-2">
                    © 2023 Powered by{" "}
                    <a href="#section-one">
                      {shopHomePageItems?.profile?.name}
                    </a>
                  </h5>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
}

export default ThemeTwoOld;
