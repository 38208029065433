import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { fetchPostalCodes } from "../../redux/Actions";
import { setValue } from "../../utility";
import { fetchGetShopByPinCode } from "../../redux/Actions/checkoutPageActions";
import DownloadApp from "../downloadApp";
import {
  partner1,
  partner2,
  partner3,
  partner4,
  partner5,
  eco,
  priorityicon14,
  priorityicon15,
  priorityicon16,
  priorityicon17,
  appdownload,
  appstore,
  googleplay,
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
  testimonialimg1,
  testimonialimg2,
  testimonialimg3,
  trustimg,
  trustimg2,
  trustimg3,
  trustimg4,
  howwe1,
  howwe2,
  howwe3,
  howwe4,
} from "../../assets/img";
import { Accordion } from "react-bootstrap";
import { fetchFaqData } from "../../redux/Actions/policyActions";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import axios from "axios";
// import BookNow from './index';

// const postalCode = [
//     { content: "GU111BH" },
//     { content: "GU111KD" },
//     { content: "GU111MH" },
// ];

function SectionTenThemeFour() {
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  return (
    <>
      <section className="testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <h2 style={{
                      color: `#${homedata?.theme_4?.home?.section_10?.h2color}`
                    }}>{homedata?.theme_4?.home?.section_10?.title}</h2>
                <p style={{
                      color: `#${homedata?.theme_4?.home?.section_10?.paracolor}`
                    }}>
                {homedata?.theme_4?.home?.section_10?.paragraph}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <Swiper
                spaceBetween={15}
                navigation={true}
                modules={[Navigation]}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                className="home-slider"
              >
            {homedata?.theme_4?.home?.section_10?.review_card.map(
              (items: any) => {
                return (
                  <SwiperSlide>
                    <div className="testimonial-card">
                      <div className="profile-user">
                        <a className="profile-user-img">
                          <img src={items.reviewer_pic} className="rounded-circle" />
                        </a>
                        <div className="profile-user-info">
                          <h6>
                            <a style={{
                      color: `#${homedata?.theme_4?.home?.section_10?.h2color}`
                    }}>{items.reviewer_name}</a>
                          </h6>
                          <div className="profile-rating">
                            {/* <span className="secondary-text">
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                              <i className="fas fa-star"></i>
                            </span> */}
                            <span className="secondary-text">
    {[...Array(5)].map((_, index) => (
      <i
        key={index}
        className={
          index < items.rating
            ? "fas fa-star"
            : "far fa-star"
        }
      ></i>
    ))}
  </span>
                            {/* <span>{items.rating}</span> */}
                          </div>
                        </div>
                      </div>
                      <h5 style={{
                      color: `#${homedata?.theme_4?.home?.section_10?.h3color}`
                    }}>{items.review_highlight}</h5>
                      <p style={{
                      color: `#${homedata?.theme_4?.home?.section_10?.paracolor}`
                    }}>{items.review}</p>
                    </div>
                  </SwiperSlide>
                );
              }
            )}
            </Swiper>
            </div>
        </div>
      </section>
    </>
  );
}

export default SectionTenThemeFour;
