import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import SectionOneThemeFive from "./section-one";
import SectionTwoThemeFive from "./section-two";
import SectionThreeThemeFive from "./section-three";
import SectionFourThemeFive from "./section-four";
import SectionFiveThemeFive from "./section-five";
import SectionSixThemeFive from "./section-six";
import SectionSevenThemeFive from "./section-seven";
import SectionEightThemeFive from "./section-eight";
import SectionOffer from "../theme-four/section-offer";
import GoogleReviews from "../googleReview";
import HomeShopList from "../homeShopList";
import { checkShopListFlow, setValue } from '../../utility';
import DailyDeals from "../dailyDeals";
import SelfServices from "./self-services";
import OurServices from "./our-services";


function ThemeFive() {
  const settingsData: any = useSelector<any>((state) => state?.settings);
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  const checkIsSectionEnabled = (index: any) => {
    if (settingsData?.template == 5) {
      return jsonData?.theme_5?.home?.sections[index]?.is_section_enable;
    }
  };

  var metaTitle = "";
  var metaDescription = "";

  useEffect(() => {
    if (window.location.href.includes('#service-section')) {
      metaTitle = settingsData?.WebsiteSettings?.site_title_en + " Services | Professional Laundry and Dry Cleaning Services in London";
      metaDescription = "Explore the range of professional laundry and dry cleaning services offered by "+ settingsData?.WebsiteSettings?.site_title_en +" in London. Book your services online today!";
      document.title = metaTitle;
      document.getElementById("title")?.setAttribute("content", metaTitle);
      document.getElementById("description")?.setAttribute("content", metaDescription);
    }else {
      metaTitle = settingsData?.WebsiteSettings?.site_title_en + " | Laundry and Dry Cleaning Services in London";
      metaDescription = settingsData?.WebsiteSettings?.site_title_en + " provides professional laundry and dry cleaning services in London. Book your laundry and dry cleaning services conveniently online today!";
      document.title = metaTitle;
      document.getElementById("title")?.setAttribute("content", metaTitle);
      document.getElementById("description")?.setAttribute("content", metaDescription);
    }
  }, []);

  return (
    <>
      {checkIsSectionEnabled(0) && <SectionOneThemeFive />}
      {checkShopListFlow() && <HomeShopList/>}
      {checkShopListFlow() && <DailyDeals/>}
      {checkIsSectionEnabled(1) && <SectionTwoThemeFive />}
      {checkIsSectionEnabled(8) &&<SelfServices/>}
      {checkIsSectionEnabled(9) &&<OurServices />}      
      <GoogleReviews />
      {/* {checkIsSectionEnabled(2) && <SectionThreeThemeFive />} */}
      {checkIsSectionEnabled(3) && <SectionFourThemeFive />}
      {checkIsSectionEnabled(4) && <SectionFiveThemeFive />}
      {checkIsSectionEnabled(5) && <SectionSixThemeFive />}
      {checkIsSectionEnabled(6) && <SectionSevenThemeFive />}
      {checkIsSectionEnabled(7) && <SectionEightThemeFive />}
    </>
  );
}

export default ThemeFive;
