import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ToastContainer,toast } from "react-toastify";
import { fetchOrderDetails } from "../../../redux/Actions/orderDetailsAction";
import TrackOrderPopup from "../../trackOrderPopup";
import { format } from "date-fns";
import {
  userimage,
  shirticon,
  shippingaddress,
  Billingaddress,
  pending,
  trackorder2,
} from "../../../assets/img";
import { log } from "console";
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import { fetchSaveReview, fetchUpdateReview } from "../../../redux/Actions/checkoutPageActions";
import { formatDistanceToNow } from 'date-fns';

function OrderView() {
  const [orderDetails, setOrderDetails] = useState<any>([]);
  const [reviewDetails, setUpdateReview] = useState<any>([]);
  const [trackOrderPopup, setTrackOrderPoup] = useState(false);
  const [orderId, setOrderId] = useState<string>("");
  const dispatch = useDispatch<any>();
  const state: any = useSelector<any>((state) => state.orderDetails.data);  
  const [open, setOpen] = useState(false);
  const params = useParams<any>();
  const [savedReview, setSavedReview] = useState<any>(null);
  const navigate = useNavigate();
  const [rating, setRating] = useState(0);
  const [reviewText, setReviewText] = useState('');
  //const viewReview: any = useSelector<any>((state) => state?.saveReview.data);
  const handleStarClick = (starIndex: number) => {
    setRating(starIndex);
    setOpen(true);
  };

  // useEffect(() => {
  //   const fetchReview = async () => {
  //     const response = await dispatch(fetchUpdateReview(orderDetails.order_id));
  //     if(response) {
  //       setSavedReview(response.data);
  //     if (response.data?.data?.review_rating) {
  //       setRating(response.data.data.review_rating);
  //       setReviewText(response.data.data.review_text);
  //     }
  //     }
      
  //   };
  //   fetchReview();
  // }, [dispatch, orderDetails.order_id]);


  const handleReviewTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReviewText(e.target.value);
  };

  const handleSaveReview = async () => {
    const edit_id = orderDetails.my_rating ? orderDetails.my_rating.id : "";
    console.log("edit_id",edit_id)
    const payloadData = {
      shop_id: orderDetails.shop_id,
      user_id: orderDetails.user_id,
      order_id:orderDetails.order_id,
      reviewer_name: orderDetails.billing_address.contact_name,
      edit_id: edit_id,
      main_category_id: 1,
      review_rating: rating,
      review_text: reviewText,
    };    
   const reviewdata = await dispatch(fetchSaveReview(payloadData)) 
   if(reviewdata){
    toast(reviewdata.message);
    setOpen(false);
   }
    console.log("saveReview")
};

  const navigateToOrder = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());

    let URL = window.location.href.split("/");
    if (URL.length > 4) {
      navigate(`/shop/${finalResult}/myOrders`);
    } else {
      navigate(`/myOrders`);
    }
  } else {
    navigate(`/myOrders`);
  }
  };

  useEffect(() => {
    if (state) {
      const { orders_details } = state;
      setOrderDetails(orders_details);
      if (orders_details.my_rating) {
        setRating(orders_details.my_rating.rating);
        setReviewText(orders_details.my_rating.review);
      }
    }
    console.log("orderDetails",orderDetails)
  }, [state]);

 

  useEffect(() => {
    if (params.orderId) {
      const orderId = params.orderId;
      setOrderId(orderId);
      dispatch(fetchOrderDetails(orderId));
      dispatch(fetchUpdateReview(orderId));
    }
    // fetchData();
    // dispatch(fetchMyOrders(limit,page,sort,order_status))
  }, []);

  const settings: any = useSelector<any>((state) => state.settings);

  function totalCount(orderDetails: any): number {
    var count: number = 0;
    if (orderDetails.order_items) {
      orderDetails.order_items.map((orderItem: any) => {
        orderItem.items.map((items: any) => {
          count += JSON.parse(items.item_quantity);
        });
      });
    }

    return count;
  }

const priceBeforeDiscount = parseFloat(orderDetails.price_before_discount || '0');
const tipAmount = parseFloat(orderDetails.tip || '0');
const result = priceBeforeDiscount - tipAmount;
const formattedResult = `${settings?.WebmasterSettings?.currency}${result.toFixed(2)}`;

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Order View</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span> Order View</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-myorders">
        <div className="container">
          <div className="row">
            <div className="col-md-12 orderview-id">
              <h1>Order ID - {orderDetails.order_id}</h1>
              <span className="float-end d-none d-md-block">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigateToOrder();
                  }}
                >
                  <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  Back to my orders
                </a>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-8 col-sm-6">
              <div className="list-count">
                <div className="menswear-checkout p-3">
                  {orderDetails.order_items &&
                    orderDetails.order_items.map((orderItems: any) => (
                      <div className="orderlist-view">
                        <div className="menswear_header">
                          <span>
                            <img src={userimage} className="img-fluid" alt="" />
                          </span>
                          <span>
                            <h3>{orderItems.main_category}</h3>
                          </span>
                        </div>
                        {/* orderItems.main_category === 'Men' &&  */}
                        {orderItems.items.map((item: any) => (
                          <div className="cloth-expand">
                            <div className="menswear-body">
                              <div className="order-total">
                                <div className="row">
                                  <div className="listcloth-lft col-md-7 col-xs-7">
                                    <h2>
                                      <img
                                        src={shirticon}
                                        className="img-fluid"
                                        alt=""
                                      />
                                      {item.sub_category}
                                    </h2>
                                    <span>{item.option_selected}</span>
                                  </div>
                                  <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                    <div className="ordered-items float-end">
                                      <span className="orderedd-item-qty">
                                        Qty : {item.item_quantity}
                                      </span>
                                      <span className="orderedd-item-price">
                                        {settings?.WebmasterSettings?.currency}{" "}
                                        {item.item_total_price}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            <h2>Cotton Shirt</h2>
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                            </div>
                          </div>
                        ))}
                        {/* { orderItems.main_category === 'Kids' && 
                                            orderItems.items.map((item: any) => (
                                            <div className="cloth-expand">
                                                <div className="menswear-body">
                                                    <div className="order-total">
                                                        <div className="row">
                                                            <div className="listcloth-lft col-md-7 col-xs-7">
                                                                <h2>{item.sub_category}</h2>
                                                                <span>{item.option_selected}</span>
                                                            </div>
                                                            <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                                <div className="ordered-items float-end">
                                                                    <span className="orderedd-item-qty">Qty : {item.item_quantity}</span>
                                                                    <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} {item.item_total_price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <br />
                                            </div>
                                            ))                                         
                                            } */}
                        {/* { orderItems.main_category === 'Womens' && 
                                            orderItems.items.map((item: any) => (
                                            <div className="cloth-expand">
                                                <div className="menswear-body">
                                                    <div className="order-total">
                                                        <div className="row">
                                                            <div className="listcloth-lft col-md-7 col-xs-7">
                                                                
                                                                <span>{item.option_selected}</span>
                                                            </div>
                                                            <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                                <div className="ordered-items float-end">
                                                                    <span className="orderedd-item-qty">Qty : {item.item_quantity}</span>
                                                                    <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} {item.item_total_price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                <br />
                                            </div>
                                            ))
                                            } */}
                      </div>
                    ))}
                  {/* <div className="orderlist-view">
                                        <div className="menswear_header">
                                            
                                        </div>
                                        <div className="cloth-expand">
                                            <div className="menswear-body">
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 2</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 10.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div>
                                    <div className="orderlist-view">
                                        <div className="menswear_header">
                                            
                                        </div>
                                        <div className="cloth-expand">
                                            <div className="menswear-body">
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="order-total">
                                                    <div className="row">
                                                        <div className="listcloth-lft col-md-7 col-xs-7">
                                                            
                                                            <span>( Washed, ironed and hung )</span>
                                                        </div>
                                                        <div className="col-md-5 col-xs-5 listcloth-rht vertical-align">
                                                            <div className="ordered-items float-end">
                                                                <span className="orderedd-item-qty">Qty : 1</span>
                                                                <span className="orderedd-item-price">{settings?.WebmasterSettings?.currency} 5.00</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                        </div>
                                    </div> */}
                </div>
                <div className="menswear-checkout box">
                  <div className="ordertotal-count">
                    <div className="orders-total">
                      <span className="lft-ordertitle">Total Count</span>
                      <span className="rht-orderamt float-end">
                        {totalCount(orderDetails)}
                        {/* {orderDetails.order_items &&
                          orderDetails.order_items.map((orderItem: any) => (
                            <span>
                             {totalCount(orderItem)}
                            </span>
                          ))} */}
                      </span>
                    </div>
                    <div className="orders-total">
                      <span className="lft-ordertitle">Payment Method</span>
                      <span className="rht-orderamt float-end">
                        {orderDetails.payment_method}
                      </span>
                    </div>
                    {!settings?.WebsiteSettings?.site_title_en.includes(
                      "Wheatley"
                    ) && (
                      <>
                        <div className="orders-total">
                          <span className="lft-ordertitle">Pickup</span>
                          <span className="rht-orderamt float-end">
                            {Object.keys(orderDetails).length > 0 &&
                              format(
                                new Date(orderDetails.pickup_date),
                                "dd-MM-yyyy"
                              )}{" "}
                            ({orderDetails.pickup_time_slot})
                          </span>
                        </div>
                        <div className="orders-total">
                          <span className="lft-ordertitle">Delivery</span>
                          <span className="rht-orderamt float-end">
                            {Object.keys(orderDetails).length > 0 &&
                              format(
                                new Date(orderDetails.delivery_date),
                                "dd-MM-yyyy"
                              )}{" "}
                            ({orderDetails.delivery_time_slot})
                          </span>
                        </div>
                      </>
                    )}
                    {orderDetails?.offer_details?.length > 0 && (
                      <div className="orders-total">
                        <span className="lft-ordertitle">Amount</span>
                        <span className="rht-orderamt float-end">
                          {orderDetails.price_currency}{" "}
                          {orderDetails.offer_details[0].total_amount}
                        </span>
                      </div>
                    )}
                    {orderDetails?.offer_details?.length == 0 && (
                      <div className="orders-total">
                        <span className="lft-ordertitle">Amount</span>
                        <span className="rht-orderamt float-end">
                          {formattedResult}
                        </span>
                      </div>
                    )}
                    {orderDetails.vat_percentage > 0 ? (
                      <>
                        <div className="orders-total">
                          <span className="lft-ordertitle">VAT</span>
                          <span className="rht-orderamt float-end">
                            ({orderDetails.vat_percentage}%){" "}
                            {orderDetails.price_currency}{" "}
                            {orderDetails.vat_amount}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {orderDetails?.tip?.value > 0 && (
                      <div className="orders-total">
                        <span className="lft-ordertitle">Tip</span>
                        <span className="rht-orderamt float-end">
                          {orderDetails.price_currency}{" "}
                          {orderDetails.tip}
                        </span>
                      </div>
                    )}
                    {orderDetails.discount_price > 0 && (
                      <div className="orders-total">
                        <span className="lft-ordertitle">Offer amount</span>
                        <span className="rht-orderamt float-end">
                          (-) {orderDetails.price_currency}{" "}
                          {orderDetails.discount_price}
                        </span>
                      </div>
                    )}
                    <div className="orders-total">
                      <span className="lft-ordertitle">Delivery Charges</span>
                      {orderDetails.pickup_and_delivery_charge > 0 ? (
                        <span className="rht-orderamt float-end">
                          {orderDetails.price_currency}{" "}
                          {orderDetails.pickup_and_delivery_charge}
                        </span>
                      ) : (
                        <span className="rht-orderamt float-end">Free</span>
                      )}
                    </div>

                    <div className="orders-total">
                      <span className="lft-ordertitle">Total Amount</span>
                      <span className="rht-orderamt float-end ordertotal-amt">
                        {orderDetails.price_currency} {orderDetails.total_amout}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-12 fb-section hidden">
                                <h3>FeedBack</h3>
                            </div> */}
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="ordertotal">
                <h2>
                  <img src={shippingaddress} className="img-fluid" alt="" />
                  Shipping Address
                </h2>
                <div className="orders-total">
                  {orderDetails.pickup_address && (
                    <p>{orderDetails.pickup_address.text_address}</p>
                  )}
                </div>
              </div>
              <div className="ordertotal">
                <h2>
                  <img src={Billingaddress} className="img-fluid" alt="" />{" "}
                  Billing Address
                </h2>
                <div className="orders-total">
                  {/* Flat 1, Park View House, 7 High Street, Aldershot, Hampshire, GU111BH */}
                  {orderDetails.billing_address && (
                    <p>{orderDetails.billing_address.text_address}</p>
                  )}
                </div>
              </div>
              <div className="btn_thankyou_mob">
                <ul className="order-status">
                  <li className="order-pending float-start">
                    <img src={pending} className="img-fluid" alt="" />
                    {orderDetails.cancelled == "1"
                      ? "Cancelled"
                      : orderDetails.order_status &&
                        orderDetails.order_status.current_status}
                  </li>
                  {/* <li className="order-approved">
                    <Link
                      to="/productLists"
                      onClick={() => {
                        localStorage.setItem(
                          "order_id",
                          JSON.stringify(orderDetails.order_id)
                        );
                        localStorage.setItem(
                          "postalCode",
                          JSON.stringify(orderDetails.postal_code)
                        );
                      }}
                    >
                      Reorder
                    </Link>
                  </li> */}
                  {orderDetails.cancelled == "0" && (
                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          setTrackOrderPoup(!trackOrderPopup);
                        }}
                        className="order-track"
                        data-bs-toggle="modal"
                        data-bs-target="#8QT3BN"
                      >
                        <img src={trackorder2} className="img-fluid" alt="" />
                        Track Order
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="rvgrid">
              {/* <Button
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                >
                  Add Review
                </Button>
                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <form>
                    <div className="form-group">
              {Array.from({ length: 5 }, (_, index) => (
                <i
                  key={index}
                  className={`fas fa-star ${index < rating ? 'text-warning' : 'text-secondary'}`}
                  onClick={() => handleStarClick(index + 1)}
                  style={{ cursor: 'pointer' }}
                ></i>
              ))}
            </div>
            <div className="form-group">
              <label>Write a Review</label>
              <textarea value={reviewText} onChange={handleReviewTextChange}></textarea>
            </div>
                      <div className="form-group">
                        <button
                          type="button"
                          className="btn btn-lg mr-14 btn-theme save_address_continue_btn"
                          id="save-address-continue-btn"
                          onClick={handleSaveReview}
                        >
                          Review
                        </button>
                      </div>

                    </form>
                  </div>
                </Collapse> */}
                {/* <Button
                className="btnreview"
        onClick={() => setOpen(!open)}
        aria-controls="example-collapse-text"
        aria-expanded={open}
      >
        Add Review
      </Button> */}
      {!open &&
      <div className="form-group rvsflex">
            {Array.from({ length: 5 }, (_, index) => (
                <i
                  key={index}
                  className={`fas fa-star ${index < rating ? 'text-warning' : 'text-secondary'}`}
                  onClick={() => handleStarClick(index + 1)}
                  style={{ cursor: 'pointer' }}
                ></i>
              ))}
          </div>
      }
      <Collapse in={open}>
        <div id="example-collapse-text">
          <form>
            <div className="form-group rvsflex">
              {Array.from({ length: 5 }, (_, index) => (
                <i
                  key={index}
                  className={`fas fa-star ${index < rating ? 'text-warning' : 'text-secondary'}`}
                  onClick={() => handleStarClick(index + 1)}
                  style={{ cursor: 'pointer' }}
                ></i>
              ))}
            </div>
            <div className="form-group rvsflextext">
              <label>Write a Review</label>
              <textarea value={reviewText} onChange={handleReviewTextChange} className="form-control"></textarea>
            </div>
            <div className="form-group">
              <button
                type="button"
                className="btn btn-lg mr-14 btn-theme save_address_continue_btn"
                id="save-address-continue-btn"
                onClick={handleSaveReview}
              >
                Review
              </button>
            </div>
          </form>
        </div>
      </Collapse>
      {savedReview && (
        <div className="saved-review">
          <div className="form-group rvsflex">
            {Array.from({ length: 5 }, (_, index) => {
              const roundedRating = Math.round(savedReview?.data?.review_rating * 2) / 2;
              if (roundedRating - index >= 1) {
                return <i className="fas fa-star" key={index}></i>;
              } else if (roundedRating - index === 0.5) {
                return <i className="fas fa-star-half-alt" key={index}></i>;
              } else {
                return <i className="far fa-star" key={index}></i>;
              }
            })}
          </div>
          <p><strong>{savedReview?.data?.reviewer_name}</strong>: {savedReview?.data?.review_text}</p>
          <div className='rpdays'>
            <small>{formatDistanceToNow(new Date(savedReview?.data?.updated_at), { addSuffix: true })}</small>
          </div>
        </div>
      )}
              </div>
            </div>
          </div>
        </div>
        {/* <div id="8QT3BN" className="modal fade" role="dialog">
                    <div className="modal-dialog vporgress-modaldialog">

                        <div className="modal-content vprogress-content">
                            <div className="modal-header">
                                Order ID - 8QT3BN
                                <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            </div>
                            <div className="modal-body vprogress-body">
                                <div className="vprogress">
                                    <div className="circle current-status">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span>Tuesday 06-12-2022, 12:09</span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span>Expeceted by Tuesday 06-12-2022 (7PM - 8PM)</span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle   notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span></span>
                                        </div>
                                    </div>
                                    <span className="bar notdone"></span>
                                    <div className="circle notdone">
                                        <span className="label"></span>
                                        <div className="progress-title">
                                            
                                            <span>Expeceted by Sunday 11-12-2022 (11AM - 12AM)</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer vprogress-footer">
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>
      {trackOrderPopup && (
        <TrackOrderPopup
          close={() => setTrackOrderPoup(false)}
          orderId={orderId}
        />
      )}
      <ToastContainer />
    </div>
  );
}

export default OrderView;
