import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

function SectionThreeThemeThree() {
  const navigate = useNavigate();
  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);

  return (
    <>
      <section className="sh3-priority-section">
        <div className="container">
          <div className="row justify-content-center">
              <div className="col-md-12">
                  <div className="sh3-section-title">
                    <span className="sh3-yellow-btn">
                      {homedata?.theme_3?.home?.section_3?.sub_heading_top}
                    </span>
                    <h2 style={{
                                color: `#${homedata?.theme_3?.home?.section_3?.h2color}`
                              }}>{homedata?.theme_3?.home?.section_3?.title}</h2>
                    <p style={{
                                color: `#${homedata?.theme_3?.home?.section_3?.paracolor}`
                              }}>{homedata?.theme_3?.home?.section_3?.paragraph}</p>
                  </div>
              </div>
          </div>
        </div>
        <div className="container">
          <div className="row justify-content-center">
            <Swiper
                  spaceBetween={30}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="home-slider"
                >
            {homedata?.theme_3?.home?.section_3?.card_section?.collection.map(
              (items: any,index: number) => {
                return (
                  <>
                    <SwiperSlide key={index}>
                      <div className="sh3-priority-card text-center">
                        <span className="sh3-priority-card-icon">
                          <img src={items.icon} />
                        </span>
                        <h3 style={{
                      color: `#${homedata?.theme_3?.home?.section_3?.h3color}`
                    }}> {items.collection_title} </h3>
                        <p style={{
                      color: `#${homedata?.theme_3?.home?.section_3?.paracolor}`
                    }}>{items.collection_paragraph}</p>
                      </div>
                    </SwiperSlide>
                  </>
                );
              }
            )}
          </Swiper>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-12">
              <div className="text-center sh3-load-more d-none">
                <Link
                  style={{
                    background: `#${homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1?.bg_color}`,
                    color: `#${homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1?.color}`,
                  }}
                  onClick={() => navigate("/aboutUs")}
                  className="btn title-btn hover-btn"
                  to={""}
                >
                  {
                    homedata?.theme_3?.home?.section_3?.card_section?.btn_group?.btn1
                      ?.text
                  }
                  <i className="uil uil-arrow-circle-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default SectionThreeThemeThree;
