import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { formatDistanceToNow } from 'date-fns';

function ShopReviews() {

  const location = useLocation();
  const { shopId } = location.state || { shopId: null };
  const allShopRatings: any = useSelector<any>((state) => state.shopsList?.data?.all_shop_ratings || []);
  const [shopRatings, setShopRatings] = useState([]);
  const [averageRating, setAverageRating] = useState(0);

  useEffect(() => {
    if (shopId && allShopRatings.length > 0) {
      const shopData = allShopRatings.find((r: any) => r.shop_ratings[0].shop_id === shopId);
      if (shopData) {
        setShopRatings(shopData.shop_ratings);
        setAverageRating(shopData.average);
      }
    }
  }, [shopId, allShopRatings]);

  const ratingCounts = [5, 4, 3, 2, 1].map((rating) => 
    shopRatings.filter((review: any) => review.rating === rating).length
  );

  const totalRatings = ratingCounts.reduce((sum, count) => sum + count, 0);

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Reviews</h2>
                <ul>
                  <li>
                    <a href="/"><i className="fa fa-home" aria-hidden="true"></i> </a>
                  </li>
                  <li>
                    <span>Reviews</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-reviews">
        <div className="container">
          <div className="row justify-content-center">
                <div className="col-md-8">
                  
                  <div className="overall-rating-container">
                    <div className="overall-rating">
                        <h2>{averageRating}</h2>
                        {Array.from({ length: 5 }, (_, index) => {
                          const roundedRating = Math.round(averageRating * 2) / 2;
                          if (roundedRating - index >= 1) {
                            return <i className="fas fa-star" key={index}></i>;
                          } else if (roundedRating - index === 0.5) {
                            return <i className="fas fa-star-half-alt" key={index}></i>;
                          } else {
                            return <i className="far fa-star" key={index}></i>;
                          }
                        })}
                        <span>{shopRatings.length} Reviews</span>
                    </div>
                    {/* This should be dynamic ratingstatus should be like status bar */}
                    <ul className='rating-status-container'>
                      {[5, 4, 3, 2, 1].map((rating, index) => (
                        <li key={rating}>
                          {rating} <i className="fas fa-star"></i>
                          <div className='rating-status-grid'>
                          <span className="ratingstatus" style={{
                            width: totalRatings > 0 ? `${(ratingCounts[index] / totalRatings) * 100}%` : '0',
                            height: "4px",
                            backgroundColor: "#ffc107",
                            display: "inline-block",
                            borderRadius: "25px"
                          }}></span>
                          </div>
                          <div className='rating-status-count'>
                            {ratingCounts[index]}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <ul className='rplist'>
                  {shopRatings.map((review: any) => (
                      <li key={review.id}>
                        <div className='rpgrid'>
                            <div className='rpstar'>
                            {Array.from({ length: 5 }, (_, index) => {
                                  const roundedRating = Math.round(review?.rating * 2) / 2;
                                  if (roundedRating - index >= 1) {
                                    return <i className="fas fa-star" key={index}></i>;
                                  } else if (roundedRating - index === 0.5) {
                                    return <i className="fas fa-star-half-alt" key={index}></i>;
                                  } else {
                                    return <i className="far fa-star" key={index}></i>;
                                  }
                                })}
                            </div>
                          <p><strong>{review.name}</strong>: {review.rating} stars</p>
                          <p>{review.review}</p>
                          <div className='rpdays'><small>{formatDistanceToNow(new Date(review.updated_at), { addSuffix: true })}</small>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
          </div>
        </div>
      </section>
    </div>


  )
}

export default ShopReviews;