import React from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import Review from "./review";
import Sec1 from "./sec1";
import Sec2 from "./sec2";
import Sec3 from "./sec3";
import Sec4 from "./sec4";
import Sec5 from "./sec5";
import Sec6 from "./sec6";
import Sec7 from "./sec7";
import Sec8 from "./sec8";
import Sec9 from "./sec9";
import SecSearch from "./sec";

function LaundryHeap() {
    let shopURL = window.location.href;

    return (
        <>
            <SecSearch/>
            <Review/>
            <Sec1/>
            {!shopURL.includes('2in1') &&
                <Sec2/>
            }
            <Sec3/>
            {/* <Sec4/> */}
            <Sec5/>
            <Sec6/>
            <Sec7/>            
            {/* <Sec4/> */}
            <Sec8/>
            <Sec9/>
        </>
    )
}

export default LaundryHeap;