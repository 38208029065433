import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { getNotification, readNotification } from "../../../redux/Actions";

function AllNotifications() {
  const notificationList: any = useSelector<any>(
    (state) => state?.all_notification
  );
  const [noNotification, setnoNotification] = useState(false);
  const [markReadNotification, setReadNotification] = useState(
    [] as Array<any>
  );
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getNotification());
  }, []);

  const markAsRead = (item: any) => {
    if (item.read != 1) {
      dispatch(readNotification(item.id));

      var newArray = markReadNotification.concat(item.id);

      newArray = newArray.filter(function (elem, pos) {
        return newArray.indexOf(elem) == pos;
      });

      setReadNotification(newArray);
    }
  };

  return (
    <div>
      <div className="breadcrumpset">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumpview">
                <h2>Notifications</h2>
                <ul>
                  <li>
                    <a href="/">
                      <i className="fa fa-home" aria-hidden="true"></i>{" "}
                    </a>
                  </li>
                  <li>
                    <span>Notifications</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="section-mobnotification">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* {notificationList.map((item: any, index: number) => (
                <div
                  className="alert alert-dismissible nf-dismissible"
                  onClick={() => markAsRead(item)}
                  key={item.id}
                >
                  <div className="notification-content">
                    <div className="nf-content">
                      <p
                        style={
                          markReadNotification.includes(item.id) ||
                          item.read == 1
                            ? { fontWeight: "normal" }
                            : { fontWeight: "bold" }
                        }
                      >
                        {index + 1}. {item.title}
                      </p>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{item.body}</span>
                    </div>
                  </div>
                </div>
              ))} */}
            </div>
            {notificationList.length == 0 && (
              <div className="text-center py-5">
                <p>No Notification Found</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}

export default AllNotifications;
