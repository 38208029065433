import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux/es/exports";
import {lh1,lh14,lh15,lh16,lh17,lh18,lh19,lh2,lh20,lh21,lh3,lh4,lh5,lh6,lh7,lh8} from "../../../assets/img";
function Sec6 () {

    const shopHomeData: any = useSelector<any>((state) => state?.shopHome);

return (
    <>
        <section className="bg-white sec-applinks">
            <div className="container">                
                <div className="row">                    
                    <div className="col-md-6">
                        <div className="sec-desc">
                            <h3>Your Laundry and Dry cleaning solution just a click away</h3>
                            <ul className="benefit-list-styl2">
                                <li>
                                    <img src={lh15}/>
                                    <p>24h turnaround time</p>
                                </li>
                                <li>
                                    <img src={lh16}/>
                                    <p>Free collection and delivery</p>
                                </li>
                                <li>
                                    <img src={lh17}/>
                                    <p>24h turnaround time</p>
                                </li>
                                <li>
                                    <img src={lh18}/>
                                    <p>Free collection and delivery</p>
                                </li>
                            </ul>
                            <div className="sec-app-links">
                                <div className="sec-all-left">
                                    <strong>Get the app</strong>
                                    <div>
                                        <a target="blank" href={shopHomeData?.webmaster_settings?.app_store_link}><img src={lh20} alt=""/></a>
                                        <a target="blank" href={shopHomeData?.webmaster_settings?.play_store_link}><img src={lh21} alt=""/></a>
                                    </div>
                                </div>
                                {/* <div className="sec-all-left">
                                    <img src={lh19} alt=""/>
                                </div> */}
                            </div>
                        </div>                        
                    </div>
                    <div className="col-md-6">
                        <div className="sec-img">
                            <img src={lh14} alt="" className="img-fluid"/>
                        </div>
                    </div>
                </div>       
            </div>
        </section>
    </>
)
}
export default Sec6;