import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShopHomeData } from "../../redux/Actions/shopHomeAction";
import { getSettingsData, setShopHomeCms } from "../../redux/Actions";
import ThemeFour from "./theme-four";
import ThemeOne from "./theme-one";
import ThemeTwo from "./theme-two";
import ThemeThree from "./theme-three";
import ThemeFive from "./theme-five";
import "./shopHome.css";
import AreaCovered from "../../pages/areaCovered";
import ThemeOneOld from "./theme1";
import ThemeTwoOld from "./theme2";
import { useLocation } from "react-router-dom";
import HeaderFiveShop from "./theme-five/headerFive";
import FooterFiveShop from "./theme-five/footerFive";
import { checkShopListFlow } from "../../utility";
import LaundryHeap from "../laundryheap";
import ThemeBlack from "../theme-black";
import IhateIroning from "../ihateironing";
import ThemeEleven from "./theme-eleven";
import ThemeNine from "./theme-nine";
import ThemeTen from "./theme-ten";
import ThemeTweleve from "./theme-tweleve";

function ShopHome() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  const Theme = shopHomeData?.home_page_settings?.theme_selected;
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  useEffect(() => {
    dispatch(getSettingsData());
    dispatch(fetchShopHomeData()).then((response: any) => {
      dispatch(setShopHomeCms(response.data.data.cms_json));
    });
  }, []);

  useEffect(() => {
    let shopURL = window.location.href;
    if(shopHomeData?.template == 1){
      import("../shopHome/theme-one/sh1.css");
    }else if(shopHomeData?.template == 2){
      import("../shopHome/theme-two/sh2.css");
    }else if(shopHomeData?.template == 3){
      import("../shopHome/theme-three/sh3.css");
    }else if(shopHomeData?.template == 4){
      import("../shopHome/theme-four/sh4.css");
    }else if(shopHomeData?.template == 5){
      import("../shopHome/theme-five/sh5.css");
    }else if(shopHomeData?.template == 9){
      import("../../assets/css/laundryheap.css");
    }else if(shopHomeData?.template == 10){
      import("../shopHome/theme-ten/sh10.css");
    }else if(shopHomeData?.template == 11){
      import("../shopHome/theme-eleven/sh11.css");
    }else if(shopHomeData?.template == 12){
      import("../shopHome/theme-tweleve/sh12.css");
    }
    if (shopURL.includes("larry'sdrycleaners") ) {
      import("../../assets/css/larry.css");
      console.log("yesyeys")
    }
  }, [shopHomeData]);

  return (
    <>
      <style type="text/css">
        {` 
            :root {
              --primary-color: #${
                jsonData?.[`theme_${shopHomeData?.template}`]?.home?.header
                  ?.primary_color
              }; !important
              --secondary-color: #${
                jsonData?.[`theme_${shopHomeData?.template}`]?.home?.header
                  ?.secondary_color
              }; !important
          }
          `}
      </style>
      <div className="shophome">
        {Theme === "orange_theme" && <ThemeOneOld />}
        {Theme === "blue_theme" && <ThemeTwoOld />}
        
        {shopHomeData?.template == 1 && checkShopListFlow() &&(
          <>
            <div className="sh1-body">
              {/* <HeaderFiveShop /> */}
              <ThemeOne />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 2 && checkShopListFlow() &&(
          <>
            <div className="sh2-body">
              {/* <HeaderFiveShop /> */}
              <ThemeTwo />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 3 && checkShopListFlow() &&(
          <>
            <div className="sh3-body">
              {/* <HeaderFiveShop /> */}
              <ThemeThree />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 4 && checkShopListFlow() &&(
          <>
            <div className="sh4-body">
              {/* <HeaderFiveShop /> */}
              <ThemeFour />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 5 && checkShopListFlow() &&(
          <>
            <div className="sh5-body">
              {/* <HeaderFiveShop /> */}
              <ThemeFive />
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 9 && checkShopListFlow() &&(
          <>
            <div className="sh9-body">
              {/* <HeaderFiveShop /> */}
              <ThemeNine/>
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 10 && checkShopListFlow() &&(
          <>
            <div className="sh10-body">
              {/* <HeaderFiveShop /> */}
              <ThemeTen/>
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 11 && checkShopListFlow() &&(
          <>
            <div className="sh11-body">
              {/* <HeaderFiveShop /> */}
              <ThemeEleven/>
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
        {shopHomeData?.template == 12 && checkShopListFlow() &&(
          <>
            <div className="sh12-body">
              {/* <HeaderFiveShop /> */}
              <ThemeTweleve/>
              {/* <FooterFiveShop /> */}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default ShopHome;
