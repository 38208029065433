import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useSelector } from "react-redux";

const priceListData = {
  data: {
    Response: {
      response_code: "",
      response_message: "",
    },
    data: {},
  },
  rowcount: 0,
  active: "active",
};

const base_url = process.env.REACT_APP_BACKEND_URL;

function Pricing() {
  const [priceList, setPriceList] = useState<any | null>(priceListData);
  const [elRefs, setElRefs] = React.useState([]);
  const [active, setActive] = useState(null);
  const [showChat, setShowChat] = useState(false);
  const settings: any = useSelector<any>((state) => state.settings);
  const [genderType, setSelectedGender] = useState('both');
  let shopURL = window.location.href;
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const queryParamsShopId = url.searchParams.get("shop_id");
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    if (queryParamsShopId) {
      shop_name = queryParamsShopId;
      currentShopId = queryParamsShopId;
    }

    const headers = {
      Accept: "application/json",
    };

    axios
      .get(`${base_url}/api/get_categories?group_id=1&shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e) => {
        setPriceList({ data: e.data });
        e.data.data.category
          .slice(0, 1)
          .map((category: any) => setActive(category.main_category_name));
      });
  }, []);

  const handleGenderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGender(e.target.value);
  };

  const handleClick = (event: any, category: any, catindex: any) => {
    setActive(category.main_category_name);
  };

  // useEffect(() => {
  //   if (shopURL.includes("hellocobbler")) {
  //     const script = document.createElement('script');
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     script.src = 'https://embed.tawk.to/66b080431601a2195ba0d615/1i4gna25d';
  //     script.charset = 'UTF-8';
  //     script.setAttribute('crossorigin', '*');
  //     document.body.appendChild(script);

  //     return () => {
  //       document.body.removeChild(script);
  //     };
  //   }
  // }, [shopURL]);

  // const handleChatClick = () => {
  //   if (showChat) {
  //     if (window.Tawk_API) {
  //       window.Tawk_API.maximize();
  //     }
  //   } else {
  //     setShowChat(true);
  //     if (window.Tawk_API) {
  //       window.Tawk_API.showWidget();
  //     }
  //   }
  // };

  return (
    <div>     
      
      <section className="abt-section">
        <div className="about-us">
          <section className="price-section" id="pricing">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    breakpoints={{
                      640: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 4,
                      },
                      1024: {
                        slidesPerView: 7,
                      },
                    }}
                  >
                    {priceList.data?.data?.category?.map(
                      (category: any, catindex: any) => (
                        <SwiperSlide key={catindex}>
                          <div className="category-container">
                            <a
                              role="button"
                              className={
                                active == category.main_category_name
                                  ? "active-swiper"
                                  : ""
                              }
                              onClick={(event) =>
                                handleClick(event, category, catindex)
                              }
                            >
                              <div className="order_list">
                                <div className="user-img">
                                  <img
                                    src={category.web_banner_img}
                                    className="img-fluid"
                                    alt=""
                                  />
                                </div>
                                <div className="list-explore">
                                  <p> {category.main_category_name}</p>
                                </div>
                              </div>
                            </a>
                          </div>
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                  {shopURL?.includes("hellocobbler") && (  
                  <div className="genderselection">
        <ul>
          <li>
            <input
              type="radio"
              id="both"
              value="both"
              checked={genderType === 'both'}
              onChange={handleGenderChange}
            />
            <label htmlFor="both">All</label>
          </li>
          <li>
            <input
              type="radio"
              id="male"
              value="male"
              checked={genderType === 'male'}
              onChange={handleGenderChange}
            />
            <label htmlFor="male">Men</label>
          </li>
          <li>
            <input
              type="radio"
              id="female"
              value="female"
              checked={genderType === 'female'}
              onChange={handleGenderChange}
            />
            <label htmlFor="female">Women</label>
          </li>
        </ul>
        
          {/* <button className="btn btntrans" onClick={handleChatClick}>
            Can't find your product?
          </button> */}
      </div>
                  )}
                  <div className="row">
                    <div className="col-md-12 list-collapse">
                      {priceList.data?.data?.category?.map(
                        (category: any, catindex: any) => (
                          <div
                            className="collapse-container first-collapse"
                            data-id="dry_cleaning"
                            data-main={catindex}
                            data-collapse-name={"slide-" + catindex}
                            key={catindex}
                          >
                            <div
                              id={"collapse-" + catindex}
                              data-uid={catindex}
                              className={
                                active == category.main_category_name
                                  ? "panel-collapse collapse show"
                                  : "panel-collapse collapse"
                              }
                              role="tabpanel"
                              aria-labelledby={"heading-" + catindex}
                              aria-expanded="true"
                            >
                              <div
                                className={
                                  active == category.main_category_name
                                    ? "panel-body collapse-body pricing-list-cloth"
                                    : "panel-body collapse-body pricing-list-cloth height-0"
                                }
                              >
                                <div className="price-box">
                                  <ul className="list-group">
                                    {category.sub_categories
                                      .filter(
                                        (subCategory: any) =>
                                          genderType === 'both' ||
                                          subCategory.gender_type === genderType
                                      )
                                      .map(
                                        (
                                          sub_category: any,
                                          subcatindex: any
                                        ) => (
                                          <li
                                            className="list-group-item"
                                            key={subcatindex}
                                          >
                                            <div className="price-list">
                                              <div className="price-pro-title">
                                                {sub_category.sub_category_name}
                                              </div>
                                              <div className="price-pro-cont">
                                                <span
                                                  className="pull-right homeprice"
                                                  data-bs-toggle="tooltip"
                                                  data-html="true"
                                                  title={sub_category.options
                                                    .filter(
                                                      (options: any) =>
                                                        options.is_default ==
                                                        "1"
                                                    )
                                                    .map(
                                                      (item: any) =>
                                                        `${item.option_name} : ${item.option_price}`
                                                    )
                                                    .join(", ")}
                                                >
                                                  {
                                                    settings?.WebmasterSettings
                                                      ?.currency
                                                  }
                                                  {sub_category.total_price}
                                                </span>
                                              </div>
                                            </div>
                                          </li>
                                        )
                                      )}
                                    {category.sub_categories.filter(
                                      (subCategory: any) =>
                                        genderType === 'both' ||
                                        subCategory.gender_type === genderType
                                    ).length === 0 && (
                                      <li className="list-group-item">
                                        No sub-categories available for the
                                        selected gender type.
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
