import React, { useEffect, useState } from "react";
import { trustimg3 } from "../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../edit/data";
import { fetchHomeJsonList } from "../../redux/Actions";

function SectionTenBannerThemeThree() {
  const dispatch = useDispatch<any>();
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);

  const homedata: any = useSelector<any>((state) => state?.homeJsonList);
  useEffect(() => {}, [homedata]);
  return (
    <>
      <section className="safety-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title">
                <span className="yellow-btn">{homedata?.theme_3?.home?.section_10?.title}</span>
                <h2 style={{
                      color: `#${homedata?.theme_3?.home?.section_10?.h2color}`
                    }}>{homedata?.theme_3?.home?.section_10?.sub_heading_top}</h2>
                <p style={{
                      color: `#${homedata?.theme_3?.home?.section_10?.paracolor}`
                    }}>{homedata?.theme_3?.home?.section_10?.mainparagraph}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="split-img">
                <img src={homedata?.theme_3?.home?.section_10?.banner} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="safety-content">
                <ul>
                  
{homedata?.theme_3?.home?.section_10?.list_Section?.map(
              (item: any, index: number) => {
                return (
                  <>
                  <li key={index}>
                    <h3 style={{
                      color: `#${homedata?.theme_3?.home?.section_10?.h3color}`
                    }}>{item.text}</h3>
                    <p style={{
                      color: `#${homedata?.theme_3?.home?.section_10?.paracolor}`
                    }}>
                    {item.paragraph}
                    </p>
                  </li>
                  </>
                  );
              }
            )}
                </ul>
              </div>
            </div>
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-3">
              <h2 style={{
                      color: `#${homedata?.theme_3?.home?.section_10?.h2color}`
                    }}>
              {homedata?.theme_3?.home?.section_10?.card_section_title}{" "}
                {/* <span className="primary-text">Detergent Sheets.</span> */}
              </h2>
            </div>
            {homedata?.theme_3?.home?.section_10?.card_section?.map(
              (item: any, index: number) => {
                return (
                  <>
            <div className="col-md-3">
              <div className="safety-list-card" style={{
                    background: `#${item.card_bg_color}`,
                  }}>
                <h3 style={{
                      color: `#${homedata?.theme_3?.home?.section_10?.cardcolor}`
                    }}>{item.card_text}</h3>
                <p style={{
                      color: `#${homedata?.theme_3?.home?.section_10?.cardcolor}`
                    }}>
                {item.card_paragraph}
                </p>
              </div>
            </div>
            </>
                );
              }
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionTenBannerThemeThree;
