import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { data } from "../data";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import {
  trustimg,
  trustimg2,
  trustimg3,
  trustimg4,
  priorityicon7,
  priorityicon8,
  priorityicon9,
} from "../../../assets/img";
import {
  fetchHomeJsonList,
  saveThemeJsonData,
  fetchCroppedImage,
} from "../../../redux/Actions";
import { ColorPicker } from "primereact/colorpicker";
import ImageCropper from "../../imageCropper/imageCropper";

function SectionThirteenThemeThreeEditBlack() {
  const dispatch = useDispatch<any>();
  const [editData, setEditData] = useState<any>();
  const jsonData: any = useSelector<any>((state) => state.homeJsonList);
  // const jsonData = data
  const [activeTab, setActiveTab] = useState(0);
  const [cropImageHeight, setCropImageHeight] = useState("");
  const [cropImageWidth, setCropImageWidth] = useState("");
  const [selectedCropImage, setSelectedCropImage] = useState("");
  const [showCropModal, setShowCropModal] = useState<boolean>(false);
  const [imagedata, setImagedata] = useState<any>("" || undefined);
  const icons = [
    "assets/icons/priorityicon14.png",
    "assets/icons/priorityicon15.png",
    "assets/icons/priorityicon16.png",
    "assets/icons/priorityicon17.png",
  ];
  const banner = [
    "assets/home/iPhone11.png",
    "assets/home/iPhone13.png",
    "assets/home/just_clickaway.png",
    "assets/home/iPhone11.png",
    "assets/home/iPhone13.png",
    "assets/home/just_clickaway.png",
  ];
  const saveJsonDataToFile = () => {
    dispatch(fetchHomeJsonList(editData));
    dispatch(saveThemeJsonData(editData));
  };

  useEffect(() => {
    setEditData(jsonData);
  }, [jsonData]);

  useEffect(() => {
    if (editData != undefined) {
      setEditData(editData);
    }
  }, [editData]);

  const addCollection = () => {
    let updatedCollection: any = [
      ...editData.theme_10.home.section_13.check_list,
    ];

    let newCard = {
      icons: "assets/icons/priorityicon14.png",
      title: "",
    };
    updatedCollection.splice(activeTab + 1, 0, newCard);
    setActiveTab(activeTab + 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        home: {
          ...prevJsonData.theme_10.home,
          section_13: {
            ...prevJsonData.theme_10.home.section_13,
            check_list: updatedCollection,
          },
        },
      },
    }));
  };

  const removeCollection = (index: any) => {
    let updatedCollection: any = [
      ...editData.theme_10.home.section_13.check_list,
    ];
    updatedCollection.splice(index, 1);
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10:{
        home: {
          ...prevJsonData.theme_10.home,
          section_13: {
            ...prevJsonData.theme_10.home.section_13,
            check_list: updatedCollection,
          },
        },
      }
    
    }));
  };

  const handleChange = (e: any, index?: any) => {
    const { value, name, type, checked } = e.target;
    let updatedCollection: any = [
      ...editData.theme_10.home.section_13.check_list,
    ];
    if (index != undefined) {
      updatedCollection = [...editData.theme_10.home.section_13.check_list];
      updatedCollection[index] = {
        ...updatedCollection[index],
        title:
          name === "check_list.title"
            ? value
            : updatedCollection.home.section_13.check_list.title,
      };
    }
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10:{
        home: {
          ...prevJsonData.theme_10.home,
          section_13: {
            ...data.home.section_13,
            [name]: value,
            title:
              name === "section_13.title"
                ? value
                : prevJsonData.theme_10.home.section_13.title,
            mobile_link_section: {
              ...prevJsonData.theme_10.home.section_13.mobile_link_section,
              [name]: value,
            },
            check_list: updatedCollection,
          },
        },
      }
      
    }));
  };
  
  const handleImageChange = (image: string, index: any) => {
    const updatedCollection = [...editData.theme_10.home.section_13.check_list];
    updatedCollection[index] = {
      ...updatedCollection[index],
      icons: image,
    };

    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        home: {
          ...prevJsonData.theme_10.home,
          section_13: {
            ...prevJsonData.theme_10.home.section_13,
            ...prevJsonData.theme_10.home.section_13.check_list,
            check_list: updatedCollection,
          },
        },
      },
    }));
  };

  const handleImageChangeone = (image: string) => {
    setEditData((prevJsonData: any) => ({
      ...prevJsonData,
      theme_10: {
        ...prevJsonData.theme_10,
        home: {
          ...prevJsonData.theme_10.home,
          section_13: {
            ...prevJsonData.theme_10.home.section_13,
            banner: image,
          },
        },
      },
    }));
  };

  const handleCroppedImage = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    if (imageLink != false) {
      handleImageChange(imageLink, activeTab);
    }
  };

  const handleCroppedImageTwo = async (image: any) => {
    setShowCropModal(false);
    setImagedata(image);
    const imageLink = await dispatch(fetchCroppedImage(image));
    // if (imageLink != false && bannerImageChange == false) {
    //   handleImageChange(imageLink, activeTab);
    // }
    if (imageLink != false) {
      handleImageChangeone(imageLink);
    }
  };

  const updateImageSectionOne = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImage(res.target.result);
      };
    }
  };

  const updateImageSectionTwo = async (e: any) => {
    const image = e.target.files[0];
    // setCropImageHeight("150");
    // setCropImageWidth("150");
    // setSelectedCropImage(image);
    // setShowCropModal(true);
    if (image instanceof File) {
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = (res: any) => {
        handleCroppedImageTwo(res.target.result);
      };
    }
  };

  return (
    <>
      <div className="modal-dialog modal-lg modal-dialog-centered theme-edit-modal">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Section13 Theme Edit</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-content modal-body">
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">
                      Title<small>(max 250 char)</small>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="section_13.title"
                      value={editData?.theme_10?.home?.section_13?.title}
                      onChange={handleChange}
                      maxLength={250}
                    />
                  </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">paragraph</label>
                    <input
                      type="text"
                      className="form-control"
                      name="paragraph"
                      value={editData?.theme_10?.home?.section_13?.paragraph}
                      onChange={handleChange}
                      maxLength={1200}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 1 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h2color"
                        value={
                          editData?.theme_10?.home?.section_13?.h2color
                        }
                      />
                      <ColorPicker
                        name="h2color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_10?.home?.section_13?.h2color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">header 2 Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="h3color"
                        value={
                          editData?.theme_10?.home?.section_13?.h3color
                        }
                      />
                      <ColorPicker
                        name="h3color"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_10?.home?.section_13?.h3color
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="mb-3">
                    <label className="form-label">paragraph Color</label>
                    <div className="input-group color-change">
                      <input
                        type="text"
                        className="form-control"
                        name="paracolor"
                        value={
                          editData?.theme_10?.home?.section_13?.paracolor
                        }
                      />
                      <ColorPicker
                        name="paracolor"
                        format="hex"
                        onChange={handleChange}
                        value={
                          editData?.theme_10?.home?.section_13?.paracolor
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                  <label className="form-label">Banner</label>
                  <div className="img-bg active">
                    <img
                      src={editData?.theme_10?.home?.section_13?.banner}
                      alt="Hero Image"
                    />
                  </div>
                  <div className="img-options">
                    <div className="row">
                      <div className="col-md-12">
                        <Swiper
                          spaceBetween={15}
                          navigation={true}
                          modules={[Navigation]}
                          breakpoints={{
                            640: {
                              slidesPerView: 1,
                            },
                            768: {
                              slidesPerView: 4,
                            },
                            1024: {
                              slidesPerView: 6,
                            },
                          }}
                          className="homejson-slider"
                        >
                          {banner.map((image: string) => {
                            return (
                              <>
                                <SwiperSlide>
                                  <div
                                    className="img-option"
                                    onClick={() => handleImageChangeone(image)}
                                  >
                                    <img
                                      src={image}
                                      alt="Hero Image"
                                      className="img-fluid"
                                    />
                                  </div>
                                </SwiperSlide>
                              </>
                            );
                          })}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionTwo}
                        />
                      </div>
            <div className="edit-section">
              <ul className="nav nav-tabs">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                  className="homejson-slider"
                >
                  {editData?.theme_10?.home?.section_13?.check_list.map(
                    (card: any, index: any) => (
                      <SwiperSlide key={index}>
                        <li key={index} className="nav-item nav-option">
                          <a
                            className={`nav-link ${
                              activeTab === index ? "active" : ""
                            }`}
                            onClick={() => setActiveTab(index)}
                          >
                            Check List {index + 1}
                          </a>
                          <a
                            className="tab-close"
                            onClick={() => {
                              removeCollection(index);
                            }}
                          >
                            <i className="fas fa-times" aria-hidden="true"></i>
                          </a>
                        </li>
                      </SwiperSlide>
                    )
                  )}
                </Swiper>
              </ul>
              <div className="tab-content">
                {editData?.theme_10?.home?.section_13?.check_list.map(
                  (card: any, index: any) => (
                    <div
                      key={index}
                      className={`tab-pane fade ${
                        activeTab === index ? "show active" : ""
                      }`}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="img-bg active">
                            <img
                              src={card.icons}
                              alt={`Card Icon ${index + 1}`}
                            />
                          </div>
                          <div className="img-options w-100">
                              <Swiper
                                spaceBetween={15}
                                navigation={true}
                                modules={[Navigation]}
                                breakpoints={{
                                  640: {
                                    slidesPerView: 1,
                                  },
                                  768: {
                                    slidesPerView: 4,
                                  },
                                  1024: {
                                    slidesPerView: 6,
                                  },
                                }}
                                className="homejson-slider"
                              >
                                {icons.map((icons, iconIndex) => (
                                  <SwiperSlide key={iconIndex}>
                                    <div
                                      className="img-option"
                                      onClick={() =>
                                        handleImageChange(icons, index)
                                      }
                                    >
                                      <img
                                        src={icons}
                                        alt="Hero Image"
                                        className="img-fluid"
                                      />
                                    </div>
                                  </SwiperSlide>
                                ))}
                              </Swiper>
                            </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <label className="form-label">
                          Title<small>(max 250 char)</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          name="check_list.title"
                          value={card.title}
                          onChange={(e) => handleChange(e, index)}
                          maxLength={250}
                        />
                      </div>
                      <div className="mb-3">
                        <label className="form-label">Upload Image</label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          className="form-control"
                          name="image"
                          onChange={updateImageSectionOne}
                        />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
            <div className="add-section">
              <div className="row">
                <div
                  onClick={() => {
                    addCollection();
                  }}
                  className="col text-center"
                >
                  Add Card<i className="fas fa-plus-square ms-2"></i>
                </div>
              </div>
            </div>
            <div className="edit-section">
              <div className="row">
                <div className="col">
                  <h4>Mobile Link Section</h4>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Android Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="android_link"
                      value={
                        editData?.theme_10?.home?.section_13?.mobile_link_section
                          ?.android_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col">
                  <div className="mb-3">
                    <label className="form-label">Ios Link</label>
                    <input
                      type="text"
                      className="form-control"
                      name="ios_link"
                      value={
                        editData?.theme_10?.home?.section_13?.mobile_link_section
                          ?.ios_link
                      }
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <button
                type="submit"
                data-bs-dismiss="modal"
                className="btn primary-btn"
                onClick={() => {
                  saveJsonDataToFile();
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCropModal ? (
        <>
          <div
            className="modal modal-sm-crop fade show onboarding-modal settings cropModal"
            id="crop-modal"
            tabIndex={-1}
            aria-labelledby="crop-modal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <ImageCropper
              src={selectedCropImage}
              height={cropImageHeight}
              width={cropImageWidth}
              handleCrop={handleCroppedImage}
              close={() => {
                setShowCropModal(false);
              }}
            />
          </div>
        </>
      ) : null}
    </>
  );
}

export default SectionThirteenThemeThreeEditBlack;
