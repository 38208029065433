import React from "react";
import {
  testimonialuser1,
  testimonialuser2,
  testimonialuser3,
  titleborder,
} from "../../../assets/img";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import { useSelector } from "react-redux";

function SectionSevenThemeTwo() {
  const jsonData: any = useSelector<any>((state) => state.shopHomeJsonList);

  return (
    <>
      <section className="sh2-testimonial-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sh2-section-title">
              <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_7?.h2color}`
                    }}>{jsonData?.theme_2?.home?.section_7?.title_1}</p>
                <h2 style={{
                      color: `#${jsonData?.theme_2?.home?.section_7?.h3color}`
                    }}>{jsonData?.theme_2?.home?.section_7?.title_2}</h2>
                <img src={titleborder} alt="" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="sh2-testimo-slider">
                <Swiper
                  spaceBetween={15}
                  navigation={true}
                  modules={[Navigation]}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 4,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {jsonData?.theme_2?.home?.section_7?.cards.map(
                    (items: any) => {
                      return (
                        <>
                          <SwiperSlide>
                            <div className="sh2-testimonial-card">
                              <div className="sh2-profile-user">
                                <a className="sh2-profile-user-img">
                                  <img src={items.profile} />
                                </a>
                                <div className="sh2-profile-user-info">
                                <h6>
                                    <a style={{
                      color: `#${jsonData?.theme_2?.home?.section_7?.h3color}`
                    }}>{items.name}</a>
                                  </h6>
                                  <p style={{
                      color: `#${jsonData?.theme_2?.home?.section_7?.paracolor}`
                    }}>{items.designation}</p>
                                </div>
                              </div>
                              <p className="testil4" style={{
                      color: `#${jsonData?.theme_2?.home?.section_7?.paracolor}`
                    }}>{items.message}</p>
                            </div>
                          </SwiperSlide>
                        </>
                      );
                    }
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SectionSevenThemeTwo;
