import { useNavigate } from "react-router-dom";
import axios from "../../config";
import { data } from "../../components/edit/data";
import { fetchShopHomeData } from "./shopHomeAction";
import { shop_data } from "../../components/shopHome/edit/data";
export const SET_POSTALCODE_LIST = "SET_POSTALCODE_LIST";
export const SET_HOMEJSON_List = "SET_HOMEJSON_List";
export const SET_SHOP_HOME_JSON_LIST = "SET_SHOP_HOME_JSON_LIST";
export const SET_NOTIFICATION = "SET_NOTIFICATION";
export const CLEAR_POSTALCODE_LIST = "CLEAR_POSTALCODE_LIST";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const CLEAR_USER_DETAILS = "CLEAR_USER_DETAILS";
export const SET_SETTINGS = "SET_SETTINGS";

export const setUserdetails = (payload: any) => ({
  type: SET_USER_DETAILS,
  payload,
});
export const clearUserdetails = () => ({
  type: CLEAR_USER_DETAILS,
});
export const getUserDetails = (payload: any) => (dispatch: any) => {
  if (payload) {
    const data = JSON.stringify(payload);
    localStorage.setItem("userDetails", data);
    dispatch(setUserdetails(payload));
  }
};
export const setSettingsAll = (payload: any) => ({
  type: SET_SETTINGS,
  payload,
});
export const setPostalCodeList = (payload: any) => ({
  type: SET_POSTALCODE_LIST,
  payload,
});
export const setHomeJsonList = (payload: any) => ({
  type: SET_HOMEJSON_List,
  payload,
});
export const setShopHomeJsonList = (payload: any) => ({
  type: SET_SHOP_HOME_JSON_LIST,
  payload,
});
export const setAllNotification = (payload: any) => ({
  type: SET_NOTIFICATION,
  payload,
});
export const fetchHomeJsonList = (jsonData: any) => async (dispatch: any) => {
  dispatch(setHomeJsonList(jsonData));
};

export const saveThemeJsonData = (jsonData: any) => async (dispatch: any) => {
  let data = JSON.stringify(jsonData);
  let url = window.location;
  let splitURL: any = url.toString().split("/");
  let payload: any = { cms_json: data };
  if (splitURL.length >= 4 && splitURL[3].includes("edit-shop-home")) {
    let shop_id = localStorage.getItem("currentShopHome"),
      tenant_id = localStorage.getItem("tenant_id");
    payload = { ...payload, tenant_id, shop_id, shop_home: true };
    
  }
  const response = await axios({
    method: "post",
    url: `/api/save_cms_json`,
    data: payload,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  if (
    response &&
    splitURL.length >= 4 &&
    splitURL[3].includes("edit-shop-home")
  ) {
    dispatch(fetchShopHomeData());
    dispatch(setShopHomeCms(jsonData));
    // dispatch(setShopHomeCms(shop_data));
  } else {
    dispatch(setHomeJsonList(jsonData));
    dispatch(getHomeJsonList());
  }
};

export const getSettingsData = () => async (dispatch: any) => {
  axios({
    method: "get",
    url: `/api/settings`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Methods": "GET, POST",
    },
  })
    .then((response) => {
      if (response.status != 401) {
        dispatch(setSettingsAll(response.data.data));
        let URL = window.location.href.split("/");
            if (!URL.includes("shop")) {
              const faviconLink = document.getElementById(
                "favicon"
              ) as HTMLLinkElement;
              if (faviconLink) {
                faviconLink.href = response.data.data.WebsiteSettings.logo_path +
                response.data.data.WebsiteSettings.style_fav
              } else {
                console.error("Favicon link element not found");
              }
            }

      }
    })
    .catch(function (error) {});
};

export const getHomeJsonList = () => async (dispatch: any) => {
  const response = await axios({
    method: "get",
    url: `/api/get_cms_json`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  if (response.data.Response.response_code == "1") {
    if (response.data.data == "") {
      dispatch(setHomeJsonList(data));
      
    } else {
      dispatch(setHomeJsonList(JSON.parse(response.data.data)));
      
    }
  }
};

export const fetchThemeEdit = (token: any) => async (dispatch: any) => {
  let data = token;
  const response = await axios({
    method: "post",
    url: `/api/validate_token`,
    data: { token: data },
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  if (response) {
    if (response.data.Response.response_code === "1") {
      return true;
    } else {
      return false;
    }
  }
};

export const setShopHomeCms = (json_data: any) => async (dispatch: any) => {
  if (json_data == "") {
    dispatch(setShopHomeJsonList(shop_data));
  } else {
    if (typeof json_data === "string") {
      dispatch(setShopHomeJsonList(JSON.parse(json_data)));
    } else {
      dispatch(setShopHomeJsonList(json_data));
    }
  }
};

export const getNotification = () => async (dispatch: any) => {
  const token = localStorage.getItem("token");

  const response = await axios({
    method: "get",
    url: `/api/user_notification_list`,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Methods": "GET, POST",
      Authorization: "Bearer " + token,
    },
  });
  

  if (response.status == 200 && response.data.data) {
    dispatch(setAllNotification(response.data.data));
  }
};

export const fetchCroppedImage = (image: any) => async (dispatch: any) => {
  let payload: any = { img: image, type: 1 };
  
  if (image instanceof File) {
    const formData = new FormData();
    formData.append("img", image);
    formData.append("type", "2");
    payload = formData;
  }

  const response = await axios({
    method: "post",
    url: `/api/image_compress`,
    data: payload,
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Methods": "GET, POST",
      "Access-Control-Allow-Origin": "*",
    },
  });
  if (response) {
    return response.data.data;
  } else return false;
};

export const fetchPostalCodes = (value: string) => async (dispatch: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;
  try {
    const bodyFormData = new FormData();
    // bodyFormData.append('keyword', value);
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "get",
      url: `${base_url}/api/get_postal_codes?keyword=${value}`,
      data: bodyFormData,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        "Access-Control-Allow-Origin": "*",
      },
    });
    // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    // const result = await response.then(response => response);

    if (response) {
      // setPostalCodeList(response.data.data[0])
      // localStorage.setItem("postalcode",response.data.postalCodeList[0]?.postcode)
      if (response.data.Response.response_code === "1") {
        dispatch(setPostalCodeList(response.data.data[0]));
      } else {
        return false;
      }
    }
  } catch (error) {}

  // axios
  //   .post(apiBase + "/auth/logout/", null, tokenConfig(getState))
  //   .then(res => {
  //     dispatch({
  //       type: LOGOUT_SUCCESS
  //     });
  //   })
  //   .catch((err) => {
  //     dispatch(returnErrors(err.response.data, err.response.status));
  //   });
};

export const readNotification = (id: number) => async (dispatch: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem("token");

  try {
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "post",
      url: `${base_url}/api/notification_mark_as_read`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        Authorization: "Bearer " + token,
      },
    });
    // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    // const result = await response.then(response => response);

    if (response) {
      if (response.data.Response) {
        // dispatch(setNotificationList(response.data.Response))
      }
    }
  } catch (error) {}
};

export const deleteProfile = () => async (dispatch: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem("token");

  try {
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "post",
      url: `${base_url}/api/delete_user_profile`,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        Authorization: "Bearer " + token,
      },
    });
    // const response = await axios.post(`${base_url}/api/get_postal_codes`,{keyword:"Gu11"});
    // const result = await response.then(response => response);

    if (response) {
      if (response.data.Response) {
        // dispatch(setNotificationList(response.data.Response))
      }
    }
  } catch (error) {}
};
export const postContactForm = async (payload: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem("token");

  try {
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "post",
      url: `${base_url}/api/send_mail`,
      data: payload,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data.Response.response_code == "1") {
      return { status: true, message: response.data.Response.response_message };
    } else {
      return {
        status: false,
        message: response.data.Response.response_message,
      };
    }
  } catch (error) {}
};

export const wheatleyPostContactForm = async (payload: any) => {
  const base_url = process.env.REACT_APP_BACKEND_URL;

  const token = localStorage.getItem("token");

  try {
    // res.header('Access-Control-Allow-Methods', 'GET, POST');
    const response = await axios({
      method: "post",
      url: `${base_url}/api/send_mail`,
      data: payload,
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Methods": "GET, POST",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data.Response.response_code == "1") {
      return { status: true, message: response.data.Response.response_message };
    } else {
      return {
        status: false,
        message: response.data.Response.response_message,
      };
    }
  } catch (error) {}
};

